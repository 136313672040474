import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HeaderModule } from './header/header.module';
import { FooterComponent } from './footer/footer.component';
import { NavigationModule } from './navigation/navigation.module';
import { RibbonComponent } from './ribbon/ribbon.component';
import { ShortcutComponent } from './shortcut/shortcut.component';
import { LayoutSwitcherComponent } from './layout-switcher.component';
import { MainLayoutComponent } from './app-layouts/main-layout.component';
import { EntranceLayoutComponent } from './app-layouts/entrance-layout.component';
import { EntranceFooterComponent } from './entrance-footer/entrance-footer.component';

import { UserModule } from '../user/user.module';

import { RouterModule } from '@angular/router';
import { NIALayoutComponent } from '@app/shared/layout/app-layouts/nia-layout.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RouteBreadcrumbsComponent } from './ribbon/route-breadcrumbs.component';
import { UtilsModule } from '../utils/utils.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { BlockUIModule } from 'primeng/blockui';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { DividerModule } from 'primeng/divider';

@NgModule({
	imports: [
		CommonModule,
		HeaderModule,
		NavigationModule,
		FormsModule,
		RouterModule,
		UserModule,

		UtilsModule,

		TooltipModule,
		BsDropdownModule,
		ConfirmDialogModule,

		// PrimeNg
		ToastModule,
		ProgressSpinnerModule,
		BlockUIModule,
		DividerModule,
	],
	declarations: [
		FooterComponent,
		RibbonComponent,
		ShortcutComponent,
		LayoutSwitcherComponent,
		MainLayoutComponent,
		EntranceLayoutComponent,
		EntranceFooterComponent,
		NIALayoutComponent,
		RouteBreadcrumbsComponent,
	],
	exports: [
		HeaderModule,
		NavigationModule,
		FooterComponent,
		RibbonComponent,
		ShortcutComponent,
		LayoutSwitcherComponent,
	],
})
export class SmartadminLayoutModule {}
