export const environment = {
	// 關閉顯示 console.log
	closeConsole: false,

	/** INFO:坤侑測試機 */
	// baseUrl: 'http://192.168.87.70/III_VRS_API/',
	// downloadUrl: 'http://192.168.87.70/',
	baseUrl: window.location.protocol + '//' + location.host.split(':')[0] + '/III_VRS_API/',
	downloadUrl: window.location.protocol + '//' + location.host.split(':')[0] + '/',

	// 控制網址 #, 單登要 false
	urlUseHash: { useHash: true },
	// INFO:開啟單登
	// 測試單登網誌 => /Web/Login?id=Administrator&unitid=27
	// app-routing.module 萬用路由導頁
	// user.service.ts 單登 API singleSignIn(dataSrc: unknown)
	// user.effects.ts 控制登出導頁 logoutSuccess$()
	// login.html 控制登入call單登API, store.dispatch(new fromUser.SingleSignOnAction(cmd))
	singleSignIn: false,

	// footer 版號
	version: 'v0.1.25',
	// 專案名稱:中文
	projectNameCH: '手語視訊轉譯中心',
	// 專案名稱:英文
	projectNameEN: 'III-VRS-FE',
	// 最新更版時間
	lastUpdate: '2024/10/08',

	firebase: {},

	debug: false,
	log: {
		auth: false,
		store: false,
	},

	smartadmin: {
		api: null,
		db: 'smartadmin-angular',
	},
};
