import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of } from 'rxjs';
import { config } from '@app/core/app.config';
import { MessageService } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import { CreateUserReportData, ProblemReporCondition, ReplyReportData } from '@app/core/models';

@Injectable({
	providedIn: 'root',
})
export class ProblemReportService {
	// constructor() {}
	// /** Catch Err function */
	// isCatchErrCode(x, apiName) {
	// 	console.log(`%c API ${apiName} Error`, 'color:#24b5aa;', x);
	// 	this.messageService.add({
	// 		severity: 'error',
	// 		summary: `API ${apiName} Error, status: ${x.status} `,
	// 		detail: '請聯繫管理員',
	// 		icon: 'bounce animated',
	// 		life: 5000,
	// 	});
	// }
	// /** 建立 Error 抓錯 */
	// getError(res, apiName) {
	// 	// console.log('建立 Error 抓錯 res:', apiName, res);
	// 	if (res.code === '400') {
	// 		console.log('400:');
	// 		this.messageService.add({
	// 			severity: 'error',
	// 			summary: `API ${apiName} Error, ${res.exception} `,
	// 			detail: '請聯繫管理員',
	// 			icon: 'bounce animated',
	// 			life: 5000,
	// 		});
	// 		return res;
	// 	} else if (res.code !== '000') {
	// 		console.log(
	// 			`%c API ${apiName} code不等於 000`,
	// 			'color:#24b5aa;',
	// 			res
	// 		);
	// 		return res;
	// 	} else {
	// 		return res;
	// 	}
	// }
	// /** 新增問題回報 */
	// public createUserReport(params: CreateUserReportData): Observable<any> {
	// 	const apiName = 'CreateUserReport';
	// 	return this.httpService
	// 		.httpPost(`${config.ProblemReportUrl}/` + apiName, params)
	// 		.pipe(
	// 			map((res) => this.getError(res, apiName)),
	// 			catchError((x) => {
	// 				this.isCatchErrCode(x, apiName);
	// 				return of();
	// 			})
	// 		);
	// }
	// /** 取得問題回報列表 */
	// public getProblemReportList(
	// 	params: ProblemReporCondition
	// ): Observable<any> {
	// 	const apiName = 'GetProblemReportList';
	// 	return this.httpService
	// 		.httpPost(`${config.ProblemReportUrl}/` + apiName, params)
	// 		.pipe(
	// 			map((res) => this.getError(res, apiName)),
	// 			catchError((x) => {
	// 				this.isCatchErrCode(x, apiName);
	// 				return of();
	// 			})
	// 		);
	// }
	// /** 回覆問題回報列表 */
	// public replyReport(params: ReplyReportData): Observable<any> {
	// 	const apiName = 'ReplyReport';
	// 	return this.httpService
	// 		.httpPost(`${config.ProblemReportUrl}/` + apiName, params)
	// 		.pipe(
	// 			map((res) => this.getError(res, apiName)),
	// 			catchError((x) => {
	// 				this.isCatchErrCode(x, apiName);
	// 				return of();
	// 			})
	// 		);
	// }
}
