import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { routerTransition } from '@app/shared/utils/animations';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

import { UserLoginData } from '@app/core/models';

/*-- Rxjs --*/
import { finalize } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

/*--  primeNg --*/
import { MessageService, ConfirmationService } from 'primeng/api';
import { LayoutService } from '@app/core/services/layout.service';

import { environment } from '@env/environment';

let _environment = environment;

@Component({
	selector: 'sa-entrance-footer',
	templateUrl: './entrance-footer.component.html',
	styleUrls: ['./entrance-footer.component.scss'],
})
export class EntranceFooterComponent implements OnInit {
	environment = environment;

	constructor(
		private store: Store<fromUser.UserState>,
		private messageService: MessageService,
		public layoutService: LayoutService,

		public router: Router,
		private viewportScroller: ViewportScroller,
		private activatedRoute: ActivatedRoute
	) {}

	ngOnInit(): void {}

	// - 取得現在路徑
	mainContentPageUrl() {
		const url = this.router.url;
		const cleanUrl = url.split('#')[0];
		return cleanUrl;
	}

	// - focus content and scroll
	focusContent(elementId) {
		const contentElement = document.getElementById(elementId);
		console.log(contentElement);
		if (contentElement) {
			setTimeout(() => {
				const yOffset = 0;
				const y = contentElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: 'smooth' });
				contentElement.focus();
			}, 200);
		}
	}
}
