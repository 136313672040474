import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import { ActivatedRoute } from '@angular/router';
import { LayoutService } from '@app/core/services';

import { ConfirmationService } from '@app/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
	selector: 'sa-relogin-dialog',
	templateUrl: './relogin-dialog.component.html',
	styleUrls: ['./relogin-dialog.component.scss'],
})
export class ReloginDialogComponent implements OnInit {
	constructor(
		public store: Store<fromUser.UserState>,
		public route: ActivatedRoute,
		public layoutService: LayoutService,
		public confirmationService: ConfirmationService,
		public ref: DynamicDialogRef,
		public dialogConfig: DynamicDialogConfig
	) {}

	ngOnInit(): void {}

	confirmKickOut() {
		this.store.dispatch(new fromUser.KickOffTokenAction(this.dialogConfig.data.userInfo));
		this.ref.close();
	}

	closeDialog() {
		this.ref.close();
	}
}
