<header
	id="header"
	class="d-flex justify-content-start align-items-center"
	(document:mousemove)="locatePosition($event)"
>
	<sa-collapse-menu></sa-collapse-menu>

	<a
		#goMainContent
		[routerLink]="mainContentPageUrl()"
		fragment="AC"
		id="goto_page_content"
		title="跳到主要內容區塊"
		tabindex="0"
		(click)="focusContent('AC')"
		(keydown)="$event.key === 'Enter' ? goMainContent.click() : null"
	>
		跳到主要內容區塊
	</a>

	<div id="logo-group">
		<div
			id="logo"
			class="d-flex"
		>
			<a
				class="d-flex align-items-center custom-focus"
				(click)="toPage('/Home/Entrance')"
				tabindex="0"
				(keydown)="$event.key === 'Enter' ? toPage('/Home/Entrance') : null"
				title="移至手語視訊轉譯中心首頁"
			>
				<div class="d-flex flex-column logo-text align-items-center">
					<div class="logo-text__tw align-items-center">
						<!-- {{ environment.projectNameCH }} -->
						<h1 style="all: unset">{{ environment.projectNameCH }}</h1>
					</div>
				</div>
			</a>
		</div>
	</div>
	<a
		#goNav
		[routerLink]="mainContentPageUrl()"
		fragment="AU"
		class="guidebrick"
		title="上方功能區塊"
		id="AU"
		accesskey="U"
		name="U"
		tabindex="0"
		(click)="focusContent('AU')"
		(keydown)="$event.key === 'Enter' ? goNav.click() : null"
	>
		:::
	</a>
	<nav style="all: unset">
		<ul
			id="U"
			class="info-page"
		>
			<li>
				<a
					class="custom-focus"
					tabindex="0"
					[routerLink]="'/Guide/Sitemap'"
					title="前往網站導覽"
				>
					網站導覽
				</a>
			</li>
			<li>
				<a
					class="custom-focus"
					tabindex="0"
					(click)="navigateToPageWithAnchor('/Home/Entrance', 'about')"
					(keydown)="$event.key === 'Enter' ? navigateToPageWithAnchor('/Home/Entrance', 'about') : null"
					title="移至關於我們"
				>
					關於我們
				</a>
			</li>
			<li>
				<a
					class="custom-focus"
					tabindex="0"
					(click)="navigateToPageWithAnchor('/Home/Entrance', 'service')"
					(keydown)="$event.key === 'Enter' ? navigateToPageWithAnchor('/Home/Entrance', 'service') : null"
					title="移至服務說明"
				>
					服務說明
				</a>
			</li>
			<li>
				<a
					class="custom-focus"
					tabindex="0"
					[routerLink]="'/Guide/Faq'"
					title="前往常見問題"
				>
					常見問題
				</a>
			</li>
		</ul>
	</nav>

	<div
		*ngIf="!currentUser?.UserId"
		class="ml-auto function-btn function-btn-mobile"
		(click)="toPage('/Web/Login')"
	>
		<em class="fa-solid fa-right-to-bracket mr-2"></em>
		註冊/登入
	</div>
	<div
		*ngIf="currentUser?.UserId"
		class="ml-auto function-btn function-btn-mobile"
		(click)="showPopup()"
	>
		<em class="fa-solid fa-right-from-bracket mr-2"></em>
		登出
	</div>

	<div class="d-flex ml-auto function-btn-ctn">
		<button
			*ngIf="!currentUser?.UserId"
			type="button"
			class="p-button-frame ml-auto function-btn-pc custom-focus"
			(click)="toPage('/Web/Login')"
		>
			會員註冊/登入
		</button>

		<button
			*ngIf="
				currentUser?.UserId &&
				(router.url.startsWith('/Web') ||
					router.url.startsWith('/Home') ||
					router.url.startsWith('/Terms') ||
					router.url.startsWith('/Guide'))
			"
			type="button"
			class="p-button-frame function-btn-pc mr-2 custom-focus"
			(click)="toPage('/Desk')"
		>
			進入服務台
		</button>

		<button
			*ngIf="currentUser?.UserId"
			type="button"
			class="p-button-frame function-btn-pc custom-focus"
			(click)="showPopup()"
		>
			登出
		</button>

		<p-avatar
			id="user-avatar"
			class="ml-2"
			*ngIf="currentUser?.UserId"
			[label]="currentUser?.Name[0]"
			shape="circle"
		></p-avatar>
	</div>

	<div class="d-flex justify-content-start align-items-center">
		<p-confirmDialog
			#logOut
			key="logOut"
			header="是否登出"
			[style]="{ width: '400px', 'font-size': '1.375rem' }"
		>
			<p-footer>
				<button
					class="p-button-outline"
					type="button"
					pButton
					icon="pi pi-times"
					label="取消"
					(click)="logOut.reject()"
				></button>

				<button
					class="p-button-primary"
					type="button"
					pButton
					icon="pi pi-check"
					label="確認"
					(click)="logOut.accept()"
					autofocus
				></button>
			</p-footer>
		</p-confirmDialog>
		<p-confirmDialog
			#confirm
			key="confirm"
			[style]="{ width: '400px', height: '350px', 'font-size': '1.25rem' }"
		>
			<p-footer>
				<div class="text-center">
					<button
						class="p-button-primary"
						type="button"
						pButton
						label="是"
						(click)="confirm.accept()"
						autofocus
					></button>
					<button
						class="p-button-danger"
						type="button"
						pButton
						label="否"
						(click)="confirm.reject()"
					></button>
				</div>
			</p-footer>
		</p-confirmDialog>
		<p-confirmDialog
			#problem
			key="problem"
			[style]="{ width: '400px', height: '250px', 'font-size': '1.25rem' }"
		>
			<p-footer>
				<div class="text-center">
					<button
						class="p-button-outline"
						type="button"
						pButton
						icon="pi pi-times"
						label="取消"
						(click)="problem.reject()"
					></button>
					<button
						class="p-button-primary"
						type="button"
						pButton
						label="提交"
						(click)="problem.accept()"
						autofocus
					></button>
				</div>
			</p-footer>
		</p-confirmDialog>
		<p-confirmPopup></p-confirmPopup>
	</div>
</header>
