import { NgModule } from '@angular/core';

// 一般及第三方匯入
import { GeneralAndThirdPartyModule } from '@app/shared/general-and-third-party.module';

// 客製化時間選取元件
import { DatepickerMinmaxComponent } from './datepicker-minmax/datepicker-minmax.component';
import { TimepickerMinmaxComponent } from './timepicker-minmax/timepicker-minmax.component';
import { MonthpickerMinmaxComponent } from './monthpicker-minmax/monthpicker-minmax.component';
import { BasicMonthpickerComponent } from './basic-monthpicker/basic-monthpicker.component';
import { BasicDatepickerComponent } from './basic-datepicker/basic-datepicker.component';

@NgModule({
	declarations: [
		BasicDatepickerComponent,
		DatepickerMinmaxComponent,
		TimepickerMinmaxComponent,
		MonthpickerMinmaxComponent,
		BasicMonthpickerComponent,
	],
	imports: [GeneralAndThirdPartyModule],
	exports: [
		BasicDatepickerComponent,
		DatepickerMinmaxComponent,
		TimepickerMinmaxComponent,
		MonthpickerMinmaxComponent,
		BasicMonthpickerComponent,
	],
})
export class DatepickerModule { }
