import { JsonApiService } from './../../services/json-api.service';
import { UserService, MessageService, LayoutService } from '@app/core/services';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs/Rx';
import { Action } from '@ngrx/store';
import * as actions from './user.actions';
import { UserLoginData, ResponseObj } from '@app/core/models';
import { map, catchError, tap, exhaustMap, finalize, mergeMap, switchMap } from 'rxjs/internal/operators';
import { of } from 'rxjs/observable/of';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { environment } from '@env/environment';
import * as moment from 'moment';
import { ConfirmationService } from '@app/core/services';
import { sharedUtils } from '@app/shared/utils/sharedUtils';

import { ReloginDialogComponent } from '@app/features/web/login/relogin-dialog/relogin-dialog.component';
import { ResetPwDialogComponent } from '@app/features/web/login/reset-pw-dialog/reset-pw-dialog.component';

// 環境變數
let _environment = environment;

@Injectable()
export class UserEffects {
	private unsubscribe: Subscription[] = []; // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/

	currentUser$: Observable<UserLoginData>;
	isLoading$: Observable<boolean>;
	currentUserSubject: BehaviorSubject<UserLoginData>;
	isLoadingSubject: BehaviorSubject<boolean>;

	get currentUserValue(): any {
		return this.currentUserSubject.value;
	}

	set currentUserValue(user: any) {
		this.currentUserSubject.next(user);
	}

	constructor(
		private action$: Actions,
		private router: Router,
		private route: ActivatedRoute,
		private userService: UserService,
		private jsonApiService: JsonApiService,
		// private notificationService: NotificationService,
		private messageService: MessageService,
		public layoutService: LayoutService,
		public confirmationService: ConfirmationService,
		public dialogService: DialogService // public dialogService: DialogService
	) {
		this.isLoadingSubject = new BehaviorSubject<boolean>(false);
		this.currentUserSubject = new BehaviorSubject<any>(undefined);
		this.currentUser$ = this.currentUserSubject.asObservable();
	}

	failContent: string;
	successContent: string;

	hasError: boolean = false;
	navigateUrl: string = '/Desk';
	returnUrl = this.route.snapshot.queryParams['returnUrl'.toString()] || '/';

	ref: DynamicDialogRef | undefined;

	@Effect()
	login$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.Login),
		exhaustMap((action: actions.LoginAction) => {
			return this.userService.userLogin(action.payload).pipe(
				map((res: ResponseObj) => {
					const LoginTime = moment(new Date()).format('YYYY-MM-DD HH:mm');
					if (res.code == '000') {
						// console.log('登入成功 res:', res);
						return new actions.LoginSuccessAction({
							...res.body,
							LoginTime,
							UserId: res.body.Id,
							Permission: ['Desk', 'ServiceLog', 'NotificationCenter', 'MemberInfo', 'ReportManagement', 'FlowGuide', 'VideoConfig', 'Home'],
							PermissionTree: [
								{
									Name: '服務台一覽',
									Id: 'Desk',
									Selected: true,
									Children: null,
								},
								{
									Name: "服務紀錄",
									Id: "ServiceLog",
									Selected: true,
									Children: null
								},
								{
									Name: '通知中心',
									Id: 'NotificationCenter',
									Selected: true,
									Children: null,
								},
								{
									Name: '會員資料',
									Id: 'MemberInfo',
									Selected: true,
									Children: null,
								},
								{
									Name: "問題回報",
									Id: "ReportManagement",
									Selected: true,
									Children: null
								},
								{
									Name: "功能引導",
									Id: "FlowGuide",
									Selected: true,
									Children: null
								},
								{
									Name: "視訊裝置設定",
									Id: "VideoConfig",
									Selected: true,
									Children: null
								},
								{
									Name: '首頁',
									Id: 'Home',
									Selected: true,
									Children: null,
								},
							],
						});
					} else if (res.code === '1007') {
						// ! 如果登入時是 1007 ，確認是否要後踢前
						this.ref = this.dialogService.open(ReloginDialogComponent, {
							header: '確認訊息',
							width: '450px',
							contentStyle: { overflow: 'auto' },
							baseZIndex: 10000,
							data: { userInfo: res },
						});

						return new actions.ReturnNullAction(res);
					} else if (res.code === '1010') {
						// ! 如果登入時是 1010，確認是否要改密碼，不改不給登
						this.ref = this.dialogService.open(ResetPwDialogComponent, {
							header: '確認訊息',
							width: '450px',
							contentStyle: { overflow: 'auto' },
							baseZIndex: 10000,
							data: { userInfo: res },
						});
						// ** 將 error state 存起來，修改密碼時使用裡面的回傳資訊
						return new actions.LoginFailNoPromptAction(res as any);
					} else {
						let content = '請確認帳號及密碼是否正確';
						switch (res.code) {
							case '118':
								content = '帳號或密碼錯誤，請確認帳號密碼';
								break;
							case '119':
								content = '驗證碼不符，請確認驗證碼';
								break;
						}

						return new actions.LoginFailAction(res);
					}
				}),
				catchError((err) => of(new actions.LoginFailAction(err)))
			);
		})
	);

	@Effect()
	kickOffToken$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.KickOffToken),
		switchMap((action: any) => {
			const param = {
				Account: action.payload.body.Account,
				Token: action.payload.body.JWTToken,
				DeviceId: sharedUtils.getDeviceId(),
				Platform: 'Web',
			};
			return this.userService.kickOutToken(param);
		}),
		map((action) => {
			const LoginTime = moment(new Date()).format('YYYY-MM-DD HH:mm');
			return new actions.LoginSuccessAction({
				...action.body,
				LoginTime,
				UserId: action.body.Id,
				Permission: ['Desk', 'ServiceLog', 'NotificationCenter', 'MemberInfo', 'ReportManagement', 'FlowGuide', 'VideoConfig', 'Home'],
				PermissionTree: [
					{
						Name: '服務台一覽',
						Id: 'Desk',
						Selected: true,
						Children: null,
					},
					{
						Name: "服務紀錄",
						Id: "ServiceLog",
						Selected: true,
						Children: null
					},
					{
						Name: '通知中心',
						Id: 'NotificationCenter',
						Selected: true,
						Children: null,
					},
					{
						Name: '會員資料',
						Id: 'MemberInfo',
						Selected: true,
						Children: null,
					},
					{
						Name: "問題回報",
						Id: "ReportManagement",
						Selected: true,
						Children: null
					},
					{
						Name: "功能引導",
						Id: "FlowGuide",
						Selected: true,
						Children: null
					},
					{
						Name: "視訊裝置設定",
						Id: "VideoConfig",
						Selected: true,
						Children: null
					},
					{
						Name: '首頁',
						Id: 'Home',
						Selected: true,
						Children: null,
					},
				],
			});
		})
	);

	@Effect()
	createMemberSuccess$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.CreateMemberSuccess),
		map((action: any) => {
			const LoginTime = moment(new Date()).format('YYYY-MM-DD HH:mm');
			return new actions.LoginSuccessAction({
				...action.payload.body,
				LoginTime,
				UserId: action.payload.body.Id,
				Permission: ['Desk', 'ServiceLog', 'NotificationCenter', 'MemberInfo', 'ReportManagement', 'FlowGuide', 'VideoConfig', 'Home'],
				PermissionTree: [
					{
						Name: '服務台一覽',
						Id: 'Desk',
						Selected: true,
						Children: null,
					},
					{
						Name: "服務紀錄",
						Id: "ServiceLog",
						Selected: true,
						Children: null
					},
					{
						Name: '通知中心',
						Id: 'NotificationCenter',
						Selected: true,
						Children: null,
					},
					{
						Name: '會員資料',
						Id: 'MemberInfo',
						Selected: true,
						Children: null,
					},
					{
						Name: "問題回報",
						Id: "ReportManagement",
						Selected: true,
						Children: null
					},
					{
						Name: "功能引導",
						Id: "FlowGuide",
						Selected: true,
						Children: null
					},
					{
						Name: "視訊裝置設定",
						Id: "VideoConfig",
						Selected: true,
						Children: null
					},
					{
						Name: '首頁',
						Id: 'Home',
						Selected: true,
						Children: null,
					},
				],
			});
		})
	);

	@Effect({ dispatch: false })
	loginSuccess$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LoginSuccess),
		tap((user: actions.LoginSuccessAction) => {
			this.messageService.add({
				severity: 'success',
				summary: '登入成功',
				detail: '歡迎使用本系統',
				life: 3000,
			});
			setTimeout(() => {
				if (!!user.payload?.IsRegister)
					this.router.navigateByUrl('/FlowGuide');
				else
					this.router.navigateByUrl('/Desk');
			}, 1000);
		})
	);

	@Effect({ dispatch: false })
	loginFail$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LoginFail),
		tap((res) => {
			this.messageService.add({
				severity: 'error',
				summary: '登入失敗',
				detail: `${(<any>res).payload.message}`,
				life: 3000,
			});
		})
	);

	@Effect({ dispatch: false })
	loginFailNoPrompt$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LoginFailNoPrompt),
		tap((res) => {
			// console.log('working');
		})
	);

	@Effect()
	logout$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.Logout),
		exhaustMap((action: actions.LogoutAction) => {
			return of(null).pipe(
				map(() => new actions.LogoutSuccessAction()),
				catchError((err) => of(new actions.LogoutFailAction(err)))
			);
		})
	);

	@Effect({ dispatch: false })
	logoutSuccess$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LogoutSuccess),
		tap((user: actions.LogoutSuccessAction) => {
			_environment.singleSignIn
				? this.router.navigateByUrl('/error/500')
				: this.router.navigateByUrl('/Web/Login');
		})
	);

	@Effect({ dispatch: false })
	logoutFail$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.LogoutFail),
		tap((action: actions.LogoutFailAction) => {
			this.messageService.add({
				severity: 'error',
				summary: '登入逾時',
				detail: '請重新登入',
				life: 3000,
			});
			this.router.navigateByUrl('/error/500');
		})
	);

	// 提供不需做任何操作的Action呼叫
	returnNull$: Observable<Action> = this.action$.pipe(
		ofType(actions.UserActionTypes.ReturnNull),
		tap((actions: actions.ReturnNullAction) => {
			// console.log('nothing happened');
		})
	);
}
