<aside id="left-panel">
	<div class="d-flex justify-content-center">
		<a
			#goSide
			[routerLink]="mainContentPageUrl()"
			fragment="AL"
			title="左方相關連結區"
			id="AL"
			accesskey="L"
			name="L"
			tabindex="0"
			(click)="focusContent('AL')"
			(keydown)="$event.key === 'Enter' ? goSide.click() : null"
		>
			:::
		</a>
	</div>
	<sa-minify-menu></sa-minify-menu>
	<!-- <sa-collapse-menu></sa-collapse-menu> -->
	<!-- 使用者資訊 -->
	<sa-login-info></sa-login-info>
	<!-- end 使用者資訊 -->
	<nav>
		<!-- NOTE: Notice the gaps after each icon usage <em></em>..
    Please note that these links work a bit different than
    traditional href="" links. See documentation for details.
    -->
		<ul saSmartMenu>
			<ng-container *ngFor="let list of navList; let idx_1 = index">
				<li>
					<a
						[ngStyle]="{
							color: router.url.split('/')[1] === list.id ? '#976401' : ''
						}"
						style="display: flex; align-items: baseline"
						[routerLink]="list.path"
						class="custom-focus"
						[title]="'移至' + list.name"
					>
						<em [class]="list.icon" class="mr-3"></em>
						<span class="menu-item-parent">{{ list.name }}</span>
					</a>
					<!-- <ng-container>
						<ul>
							<li *ngFor="let sub of list.subList; let idx_2 = index">
								<a *ngIf="sub.subList !== undefined">
									<span class="menu-item-parent">{{ idx_1 + 1 }}.{{ idx_2 + 1 }} {{ sub.name }}</span>
								</a>
								<a
									*ngIf="sub.subList === undefined"
									[routerLink]="sub.path"
								>
									<span class="menu-item-parent">{{ idx_1 + 1 }}.{{ idx_2 + 1 }} {{ sub.name }}</span>
								</a>
								<ng-container *ngIf="sub.subList?.length">
									<ul>
										<li *ngFor="let sub of sub.subList; let idx_3 = index">
											<a [routerLink]="sub.path">
												<span class="menu-item-parent">{{ idx_1 + 1 }}.{{ idx_2 + 1 }}.{{ idx_3
													+ 1 }} {{ sub.name }}</span>
											</a>
										</li>
									</ul>
								</ng-container>
							</li>
						</ul>
					</ng-container> -->
				</li>
			</ng-container>
		</ul>
	</nav>
</aside>
