import { RoleGroupService } from './rolegroup.service';
import { tap, toArray } from 'rxjs/operators';
import { JsonApiService } from './json-api.service';
import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, throwError } from 'rxjs';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { catchError, map, shareReplay, switchMap } from 'rxjs/internal/operators';
import { config } from '@app/core/app.config';
import { MessageService } from 'primeng/api';
import { CreateSysCode, EditSysCode, SysCodeCondition } from '../models';

@Injectable({
	providedIn: 'root',
})
export class SyscodeService {
	// // Catch Err function
	// isCatchErrCode(x, apiName) {
	// 	console.log(`%c API ${apiName} Error`, 'color:#24b5aa;', x);
	// }
	// // 建立 Error 抓錯
	// getError(res, apiName) {
	// 	if (res.code !== '000') {
	// 		console.log(`%c API ${apiName} code不等於 000`, 'color:#24b5aa;', res);
	// 		return res;
	// 	} else {
	// 		return res;
	// 	}
	// }
	// constructor(
	// 	private httpService: HttpClientService,
	// 	private jsonApiService: JsonApiService,
	// 	private roleGroupService: RoleGroupService // Toast
	// ) {}
	// // INFO:建立 BehaviorSubject START---------------------------------------------------
	// _Boolean$ = new BehaviorSubject<void>(null); /** true/false */
	// _Role$ = new BehaviorSubject<void>(null); /** 角色 */
	// _Dept$ = new BehaviorSubject<void>(null); /** 部門 */
	// _Grade$ = new BehaviorSubject<void>(null); /** 職級 */
	// _ProblemType$ = new BehaviorSubject<void>(null); /** 回報類型 */
	// _ProcessStatus$ = new BehaviorSubject<void>(null); /** 回報處理進度 */
	// _EditSysType$ = new BehaviorSubject<void>(null); /** 可編輯的代碼類型 */
	// // INFO:建立 BehaviorSubject END---------------------------------------------------
	// // INFO:API syscode START--------------------------------------------------
	// Boolean$ = this.jsonApiService.fetch('/syscode/boolean.json').pipe(
	// 	// 模擬後端 API 串接回覆讓 getError 去接
	// 	map((res) => this.getError(res, 'Boolean')),
	// 	map((res) => res.body),
	// 	catchError((x) => {
	// 		this.isCatchErrCode(x, 'Boolean');
	// 		return of();
	// 	}),
	// 	shareReplay(1)
	// );
	// /** 部門 */
	// Dept$ = this._Dept$.pipe(
	// 	switchMap(() => this.getSysCodeByType({ Type: 'Dept' })),
	// 	map((res) => res.body),
	// 	shareReplay(1)
	// );
	// /** 職級 */
	// Grade$ = this._Grade$.pipe(
	// 	switchMap(() => this.getSysCodeByType({ Type: 'Grade' })),
	// 	map((res) => res.body),
	// 	shareReplay(1)
	// );
	// /** 回報類型 */
	// ProblemType$ = this._ProblemType$.pipe(
	// 	switchMap(() => this.getSysCodeByType({ Type: 'ProblemType' })),
	// 	map((res) => res.body),
	// 	shareReplay(1)
	// );
	// /** 回報處理進度 */
	// ProcessStatus$ = this._ProcessStatus$.pipe(
	// 	switchMap(() => this.getSysCodeByType({ Type: 'ProcessStatus' })),
	// 	map((res) => res.body),
	// 	shareReplay(1)
	// );
	// /** 角色 */
	// Role$ = this._Role$.pipe(
	// 	switchMap(() => this.roleGroupService.getRoleGroupList()),
	// 	map((res) =>
	// 		res.body.map((x) => ({
	// 			value: x.Id,
	// 			label: x.CName,
	// 		}))
	// 	),
	// 	shareReplay(1)
	// );
	// EditSysType$ = this._EditSysType$.pipe(
	// 	switchMap(() => this.getEditableSysType()),
	// 	map((res) => res.body),
	// 	shareReplay(1)
	// );
	// // INFO:API syscode END--------------------------------------------------
	// // INFO:更新shareReplay cach START--------------------------------------------------
	// syscodeUpdate = {
	// 	Role: (_this) => _this._Role$.next(), // 角色
	// 	Dept: (_this) => _this._Dept$.next(), // 部門處室
	// 	Grade: (_this) => _this._Grade$.next(), // 職務級別
	// 	ProblemType: (_this) => _this._ProblemType$.next(), // 回報類型
	// 	ProcessStatus: (_this) => _this._ProcessStatus$.next(), // 回報處理進度
	// };
	// // INFO:更新shareReplay cach END--------------------------------------------------
	// // INFO:功能型 function START-----------------------------------------------------
	// /** 取得系統代碼類型 */
	// public getSysType(): Observable<any> {
	// 	return this.httpService.httpPost(`${config.SysCodeUrl}/GetSysType`, '');
	// }
	// /** 依Type取得系統代碼 */
	// public getSysCodeByType(params: SysCodeCondition): Observable<any> {
	// 	const apiName = 'GetSysCodeByType';
	// 	return this.httpService.httpPost(`${config.SysCodeUrl}/` + apiName, params).pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }
	// /** 取得可以編輯的系統代碼類型 */
	// public getEditableSysType(): Observable<any> {
	// 	const apiName = 'GetEditableSysType';
	// 	return this.httpService.httpPost(`${config.SysCodeUrl}/` + apiName, '').pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }
	// /** 新增代碼 */
	// public createSysCode(params: CreateSysCode): Observable<any> {
	// 	const apiName = 'CreateSysCode';
	// 	return this.httpService.httpPost(`${config.SysCodeUrl}/` + apiName, params).pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }
	// /** 編輯、作廢代碼 */
	// public editSysCode(params: EditSysCode): Observable<any> {
	// 	const apiName = 'EditSysCode';
	// 	return this.httpService.httpPost(`${config.SysCodeUrl}/` + apiName, params).pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }
	// // INFO:功能型 function END-----------------------------------------------------
	// // FIXME: **********************
	// initSyscode() {}
}
