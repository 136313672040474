import { NgModule } from '@angular/core';

// 一般及第三方匯入
import { GeneralAndThirdPartyModule } from '@app/shared/general-and-third-party.module';
import { KunyouTableComponent } from './kunyou-table/kunyou-table.component';

@NgModule({
  declarations: [KunyouTableComponent],
	imports: [GeneralAndThirdPartyModule],
  exports: [KunyouTableComponent],
})
export class PtableModule { }
