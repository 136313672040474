import { environment } from '../../environments/environment';
const baseUrl: string = environment.baseUrl + 'api/';

export const config: any = {
	baseUrl: baseUrl,
	/** 單登 */
	SingleUrl: baseUrl + 'Single',

	//** FEMember 使用者相關 */
	FEMemberUrl: baseUrl + 'FEMember',

	//** FEInformationDesk 服務台相關 */
	FEInformationDeskUrl: baseUrl + 'FEInformationDesk',

	//** FEBulletin 通知中心(佈告欄)相關 */
	BillboardUrl: baseUrl + 'FEBulletin',

	//** FENotification 簡訊驗證碼相關 */
	NotificationUrl: baseUrl + 'FENotification',

	/** 系統代碼 */
	SysCodeUrl: baseUrl + 'SysCode',

	/** 儀錶板 */
	DashboardUrl: baseUrl + 'Dashboard',

	/** 權限角色 */
	RoleGroupUrl: baseUrl + 'RoleGroup',
	/** 圖形驗證 */
	AuthorizationUrl: baseUrl + 'Authorization',
	/** 使用者紀錄 */
	LogAuditUrl: baseUrl + 'LogAudit',
	/** 問題回報 */
	ProblemReportUrl: baseUrl + 'ProblemReport',

	/** 3 案件開立 */
	ApplicationApplyUrl: baseUrl + 'ApplicationApply',
	/** 4 證物管理 */
	EvidenceManagementUrl: baseUrl + 'EvidenceManagement',
	/** 4.3 證物管理(複本) */
	CopyManagement: baseUrl + 'CopyManagement',
	// CaseUrl: baseUrl + 'EvidenceManagement',
	/** 5 鑑定管理 */
	AppraisalManagementUrl: baseUrl + 'AppraisalManagement',
	/** 5.3 鑑定作業 */
	IdentificationUrl: baseUrl + 'Identification',
	/** 5.4 鑑定審核、5.5 鑑定結果列表 */
	AuditResults: baseUrl + 'AuditResults',

	/** 7. 六都核分 */
	FileManageUrl: baseUrl + 'FileManage',
	MultipartUploadUrl: baseUrl + 'MultipartUpload',

	// INFO:舊的 ----------------------------------------------
	/** 數位鑑定收案 */
	AcceptUrl: baseUrl + 'Accept',
	/** 數位鑑定紀錄 */
	ExperimentUrl: baseUrl + 'Experiment',
	/** 數位鑑定報告 */
	VerificationResultUrl: baseUrl + 'VerificationResult',
	/** 數位鑑定意見回饋 */
	GiveBackUrl: baseUrl + 'GiveBack',
	/** 數位證據檔案 */
	fileManagementUrl: baseUrl + 'FileManagement',
	// CaseUrl: baseUrl + 'ApplicationApply',
	/** 資產 */
	AssetsUrl: baseUrl + 'Assets',
	/** 知識 */
	KnowledgeUrl: baseUrl + 'Knowledge',
	/** 關鍵字檢索 */
	SearchUrl: baseUrl + 'Search',
	/** Log紀錄 */
	RecordUrl: baseUrl + 'Record',
	/** 視覺化 */
	VisualizeUrl: baseUrl + 'Visualize',
	/** 簽名 */
	DigitalSignUrl: baseUrl + 'DigitalSign',

	logoutUrl: baseUrl + 'auth/logout',
	reLoadSessionUrl: baseUrl + 'auth/reLoadSession',
	versionUrl: baseUrl + 'auth/version',
};
