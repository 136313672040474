import { LayoutService } from '@app/core/services/layout.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService, JsonApiService } from '@app/core/services';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';
import { UserLoginData } from '@app/core/models';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

@Component({
	selector: 'sa-login-info',
	templateUrl: './login-info.component.html',
	styleUrls: ['./login-info.component.scss'],
})
export class LoginInfoComponent implements OnInit {
	currentUser$: Observable<UserLoginData>;
	currentUser: UserLoginData;

	constructor(
		private router: Router,
		private store: Store<fromUser.UserState>,
		private userService: UserService,
		private layoutService: LayoutService,
		private jsonApiService: JsonApiService
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}

	async ngOnInit() {
		// this.currentUser = await this.currentUser$.take(1).toPromise<User>();
		this.currentUser$.subscribe((v) => {
			this.currentUser = v;
		});
	}

	toggleShortcut() {
		this.layoutService.onShortcutToggle();
	}
}
