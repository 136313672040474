import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { SyscodeService, LayoutService, JsonApiService, MessageService, UserService } from '@app/core/services';
import 'rxjs/add/observable/of';
import { UserLoginData } from '@app/core/models';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

import { CountdownComponent } from 'ngx-countdown';

/*-- Rxjs --*/
import { finalize, switchAll, switchMap, tap, map, filter } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';

// PrimeNg
import { ConfirmationService } from 'primeng/api';

// 環境變數
import { environment } from '../../../../../src/environments/environment';

declare var $: any;

@Component({
	selector: 'sa-header',
	templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
	// 環境變數
	environment = environment;

	currentUser$: Observable<UserLoginData>;
	currentUser;

	@ViewChild('countdown', { static: true }) countdown: CountdownComponent;

	// INFO:正式用
	leftTime: number = 1200; // 倒數時間(秒) 20分鐘
	notify: number = 300; // 警示秒數:reLogin() 5分鐘
	checkPositionTime: number = 1; // 每1分鐘 檢測鼠標歷史位置 與 現位置 差異

	// INFO:測式用
	// leftTime: number = 30; // 倒數時間(秒) 測試用30秒
	// notify: number = 15; //  警示秒數:reLogin() 測試用15秒
	// checkPositionTime: number = 0.1; // 檢測鼠標歷史位置 測試用 10秒

	countdownConfig = {
		leftTime: this.leftTime,
		notify: [this.notify],
		format: '登出倒數：m分s秒',
		demand: false,
	};

	// 在線人數
	onlineUserCnt = 1;

	// 滑鼠座標
	mXCurrent: number = 0;
	mYCurrent: number = 0;
	mXBefore: number = 0;
	mYBefore: number = 0;

	constructor(
		public router: Router,
		private store: Store<fromUser.UserState>,
		private layoutService: LayoutService,
		private syscodeService: SyscodeService,
		private confirmationService: ConfirmationService,
		private messageService: MessageService,
		private viewportScroller: ViewportScroller,
		private activatedRoute: ActivatedRoute,
		private userService: UserService,
		private elementRef: ElementRef
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
	}
	ngOnInit(): void {
		// this.countdown.begin();

		// setInterval(() => {
		// 	// 每1分鐘 檢測是否閒置
		// 	this.checkIdle();
		// }, this.checkPositionTime * 60000);

		of(this.layoutService.setPageLoading(true))
			.pipe(
				switchMap(() => this.currentUser$),
				tap((user) => (this.currentUser = user)),
				// tap(() => this.syscodeService.initSyscode()),
				finalize(() => this.layoutService.setPageLoading(false))
			)
			.subscribe();
	}

	// 檢測是否閒置
	checkIdle() {
		// console.log('檢測是否閒置 checkIdle:');
		// 如果滑鼠位置相同 代表閒置
		if (this.mXBefore === this.mXCurrent && this.mYBefore === this.mYCurrent) {
			// console.log('檢測是否閒置 YES:');
			this.start();
		}

		// console.log('檢測是否閒置 NO:');

		this.mXBefore = this.mXCurrent;
		this.mYBefore = this.mYCurrent;
	}

	locatePosition(e) {
		// 標記滑鼠位置
		this.mXCurrent = e.clientX;
		this.mYCurrent = e.clientY;
		// this.pause();
	}

	start() {
		this.countdown.begin();
	}

	// pause() {
	// 	// console.log('pause:');
	// 	if (this.countdown.left < 1200000) {
	// 		this.countdown.restart(); // 重新倒數
	// 		this.countdown.pause();
	// 		return;
	// 	}
	// 	this.countdown.pause();
	// }

	gotoUserManagement() {
		this.router.navigate(['/System/UserManagement']);
	}

	handleEvent(e: Event) {
		switch (e['action']) {
			case 'notify':
				this.reLogin();
				break;
			case 'done':
				this.logout();
				break;
		}
	}

	onMenuOnTop() {
		this.layoutService.onMenuOnTop();
	}

	reLogin() {
		this.confirmationService.confirm({
			key: 'logOut',
			header: `是否要登出`,
			icon: 'fa fa-sign-out',
			message: `登出倒數即將結束，系統將在${this.notify / 60}分鐘後自動登出`,
			accept: () => {
				this.messageService.add({
					severity: 'success',
					summary: '請繼續使用',
					detail: '系統重新倒數',
					life: 3000,
				});
				this.countdown.restart(); // 重新倒數
			},
			reject: () => {
				this.messageService.add({
					severity: 'warn',
					summary: '時間到 已登出',
					detail: '若要繼續使用請重新登入...',
					life: 3000,
				});
			},
		});
	}

	logout() {
		let input = {
			UserId: this.currentUser.UserId,
		};
		let user: UserLoginData = { ...input };
		// console.log(user);
		this.store.dispatch(new fromUser.LogoutAction(user));

		// this.messageService.add({
		// 	severity: 'success',
		// 	summary: '已登出',
		// 	detail: '謝謝您',
		// 	life: 3000,
		// });
	}
	confirm() {
		this.confirmationService.confirm({
			message: 'Are you sure that you want to perform this action?',
			accept: () => {
				//Actual logic to perform a confirmation
			},
		});
	}

	// 是否要登出
	showPopup() {
		this.confirmationService.confirm({
			key: 'logOut',
			header: `是否要登出`,
			// icon: 'fa fa-sign-out txt-color-warning',
			message: `<div class="my-4">是否要登出 <span class='txt-color-orangeDark'><strong>${this.currentUser.Name}</strong></span> ?</div>`,
			accept: () => {
				of(this.layoutService.setPageLoading(true))
					.pipe(
						switchMap(() => this.userLogout$({})),
						tap(() => console.log('working')),
						tap(() => this.logout()),
						finalize(() => this.layoutService.setPageLoading(false))
					)
					.subscribe();
			},
			reject: () => {},
		});
	}

	onToggle() {
		var $body = $('body');
		var documentMethods = {
			enter: ['requestFullscreen', 'mozRequestFullScreen', 'webkitRequestFullscreen', 'msRequestFullscreen'],
			exit: ['cancelFullScreen', 'mozCancelFullScreen', 'webkitCancelFullScreen', 'msCancelFullScreen'],
		};

		if (!$body.hasClass('full-screen')) {
			$body.addClass('full-screen');
			document.documentElement[
				documentMethods.enter.filter((method) => {
					return document.documentElement[method];
				})[0]
			]();
		} else {
			$body.removeClass('full-screen');
			document[
				documentMethods.exit.filter((method) => {
					return document[method];
				})[0]
			]();
		}
	}

	// - 導航至頁面
	toPage(route: string) {
		this.router.navigateByUrl(route);
	}

	// - 滑移到頁面
	navigateToPageWithAnchor(pageUrl: string, anchor: string): void {
		const isCurrentlyAtHome = pageUrl.startsWith('/Home') && this.router.url.startsWith('/Home');
		if (isCurrentlyAtHome) {
			this.router.navigateByUrl('/Home/Entrance').then(() => {
				const element = document.querySelector('#' + anchor);
				const yOffset = -60;
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: 'smooth' });
			});
			return;
		}
		const navigationExtras: NavigationExtras = { fragment: anchor };
		this.router.navigate([pageUrl], navigationExtras);
	}

	// - 取得現在路徑
	mainContentPageUrl() {
		const url = this.router.url;
		const cleanUrl = url.split('#')[0];
		return cleanUrl;
	}

	// - focus content and scroll
	focusContent(elementId) {
		if (elementId === 'AU') return;
		const contentElement = document.getElementById(elementId);
		if (contentElement) {
			setTimeout(() => {
				const yOffset = -80;
				const y = contentElement.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: 'smooth' });
				contentElement.focus();
			}, 200);
		}
	}

	// ? API
	userLogout$(param): Observable<ResponseType> {
		return this.userService.userLogout(param).pipe(
			tap((res) => {
				console.log('API 使用者登出', res);
			})
		);
	}
}
