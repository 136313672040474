import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of } from 'rxjs';
import { config } from '@app/core/app.config';
import { MessageService } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

@Injectable({
	providedIn: 'root'
})
export class ServiceLogService {

	constructor(
		private http: HttpClient,
		private httpService: HttpClientService,
		private store: Store,
		private messageService: MessageService // Toast
	) {}

	//** Catch Err function
	isCatchErrCode(x, apiName) {
		// console.log('x:', x);
		if (x.status === 401) {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else {
			// console.log(`%c API ${apiName} Error`, 'color:#24b5aa;', x);
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, status: ${x.status} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
		}
	}

	//** 建立 Error 抓錯
	getError(res, apiName) {
		if (res.code === '400') {
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, ${res.exception} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
			return res;
		} else if (res.code === '401') {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code === '1015') {
			this.messageService.add({
				severity: 'error',
				summary: res.message,
				// detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code !== '000') {
			return res;
		} else {
			return res;
		}
	}

	//** 取得服務紀錄列表 */
	public getServiceLogList(params): Observable<any> {
		const apiName = 'GetServiceLogList';
		return this.httpService.httpPost(`${config.ServiceLogUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}
}
