import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationExtras, ActivatedRoute } from '@angular/router';
import { ViewportScroller } from '@angular/common';

import { routerTransition } from '@app/shared/utils/animations';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

import { UserLoginData } from '@app/core/models';

/*-- Rxjs --*/
import { finalize } from 'rxjs/operators';
import { of, Observable } from 'rxjs';

/*--  primeNg --*/
import { MessageService, ConfirmationService } from 'primeng/api';
import { LayoutService } from '@app/core/services/layout.service';

@Component({
	selector: 'app-entrance-layout',
	templateUrl: './entrance-layout.component.html',
	styleUrls: ['./layout.component.scss'],
	animations: [routerTransition],
	providers: [MessageService, ConfirmationService],
})
export class EntranceLayoutComponent implements OnInit {
	currentUser$: Observable<UserLoginData>;
	currentUser: UserLoginData;

	constructor(
		private store: Store<fromUser.UserState>,
		private messageService: MessageService,
		public layoutService: LayoutService,

		public router: Router,
		private viewportScroller: ViewportScroller,
		private activatedRoute: ActivatedRoute
	) {
		this.currentUser$ = store.select(fromUser.getCurrentUser);
		this.currentUser$.subscribe((v) => (this.currentUser = v));
	}

	ngOnInit(): void {
		of(this.layoutService.setPageLoading(true))
			.pipe(finalize(() => this.layoutService.setPageLoading(false)))
			.subscribe();
	}

	getState(outlet) {
		if (!outlet.activatedRoute) return;
		let ss = outlet.activatedRoute.snapshot;
		// return unique string that is used as state identifier in router animation
		return (
			outlet.activatedRouteData.state ||
			(ss.url.length ? ss.url[0].path : ss.parent.url.length ? ss.parent.url[0].path : null)
		);
	}

	// - 滑移到頁面
	navigateToPageWithAnchor(pageUrl: string, anchor: string): void {
		const isCurrentlyAtHome = pageUrl.startsWith('/Home') && this.router.url.startsWith('/Home');
		if (isCurrentlyAtHome) {
			this.router.navigateByUrl('/Home/Entrance').then(() => {
				const element = document.querySelector('#' + anchor);
				const yOffset = -60;
				const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
				window.scrollTo({ top: y, behavior: 'smooth' });
			});
			return;
		}
		const navigationExtras: NavigationExtras = { fragment: anchor };
		this.router.navigate([pageUrl], navigationExtras);
	}

	// - 取得現在路徑
	mainContentPageUrl() {
		const url = this.router.url;
		const cleanUrl = url.split('#')[0];
		return cleanUrl;
	}

	// - focus content and scroll
	focusContent(elementId) {
		const contentElement = document.getElementById(elementId);
		if (contentElement) {
			contentElement.focus();
			contentElement.scrollIntoView({ behavior: 'smooth' });
		}
	}
}
