import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of } from 'rxjs';
import { config } from '@app/core/app.config';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

@Injectable({
	providedIn: 'root',
})
export class DeskService {
	constructor(
		private http: HttpClient,
		private httpService: HttpClientService,
		private messageService: MessageService, // Toast
		private router: Router,
		private store: Store
	) {}

	//** Catch Err function
	isCatchErrCode(x, apiName) {
		// console.log('x:', x);
		if (x.status === 401) {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else {
			// console.log(`%c API ${apiName} Error`, 'color:#24b5aa;', x);
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, status: ${x.status} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
		}
	}

	//** 建立 Error 抓錯
	getError(res, apiName) {
		if (res.code === '400') {
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, ${res.exception} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
			return res;
		} else if (res.code === '401') {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code === '1015') {
			this.messageService.add({
				severity: 'error',
				summary: res.message,
				// detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code !== '000') {
			return res;
		} else {
			return res;
		}
	}

	//** 取得服務台管理列表 */
	public getInformationDeskList(
		param: {
			UsingPaging: boolean;
			NumberOfPperPage: number;
			PageIndex: number;
		} = {
			UsingPaging: false,
			NumberOfPperPage: 0,
			PageIndex: 0,
		}
	): Observable<any> {
		const apiName = 'GetInformationDeskList';
		return this.httpService.httpPost(config.FEInformationDeskUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 服務台創建 */
	public createInformationDesk(param): Observable<any> {
		const apiName = 'CreateInformationDesk';
		return this.httpService.httpPost(config.FEInformationDeskUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 服務台移除 */
	public deleteInformationDesk(param): Observable<any> {
		const apiName = 'DeleteInformationDesk';
		return this.httpService.httpPost(config.FEInformationDeskUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}
}
