<div class="main__layout">
	<sa-header></sa-header>

	<sa-navigation *ngIf="currentUser"></sa-navigation>

	<div id="main" class="" role="main" [@routerTransition]="getState(o?._activatedRoute ? o : '')">
		<router-outlet #o="outlet"></router-outlet>
		<!-- 增加 toast 讓子層也可以控制 -->
		<p-toast></p-toast>
	</div>

	<sa-layout-switcher class="d-none demo_custom"></sa-layout-switcher>

	<p-blockUI [baseZIndex]="999999" [blocked]="this.layoutService.pageLoading$ | async">
		<p-progressSpinner></p-progressSpinner>
	</p-blockUI>
</div>
