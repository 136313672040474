import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { UserLoginData } from '@app/core/models';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
	selector: 'sa-navigation',
	templateUrl: './navigation.component.html',
})
export class NavigationComponent implements OnInit {
	currentUser$: Observable<UserLoginData>;
	currentUser: UserLoginData;

	icons = {
		Desk: 'fa-solid fa-headphones fa-regular',
		NotificationCenter: 'fa-solid fa-bell fa-regular',
		MemberInfo: 'fa-solid fa-user fa-regular',
		Home: 'fa-solid fa-home fa-regular',
	};

	list = [];
	navList = [];

	currentURL;

	// 頁籤顯示
	constructor(private store: Store<fromUser.UserState>, public router: Router) {
		this.currentUser$ = this.store.select(fromUser.getCurrentUser);
		this.currentUser$.subscribe((res) => {
			if (res === null) return;
			// this.InitNav(res.PermissionTree || this.list);
			const _permissionTree = JSON.parse(JSON.stringify(res.PermissionTree)); // 深拷貝
			this.InitNav(_permissionTree || this.list);
		});
	}

	ngOnInit() {
		// this.currentUser = await this.currentUser$.take(1).toPromise<User>();

		// same URL trigger reload
		this.currentURL = this.router.url;

		this.router.events.subscribe((event: Event) => {
			if (event instanceof NavigationEnd) {
				if (this.currentURL === event.url) {
					window.location.reload();
				}
				this.currentURL = event.url;
			}
		});
	}

	InitNav(list) {
		// let sort = (obj) => {
		// 	const newChild = obj.Children.filter((r) => r.Selected);
		// 	return { ...obj, Children: newChild };
		// };
		let buildData = (arr) => {
			return arr.flatMap((r) => {
				return {
					name: r.Name,
					id: r.Id,
					icon: this.icons[r.Id],
					subList: null,
					path: r.Id,
				};
			});
		};

		// let result = list
		// 	.map((item: any) => {
		// 		if (item.Children.length > 0) {
		// 			item.Children.map((x: any) => {
		// 				// console.log('第二層 x.Children:', x.Children);
		// 				if (x.Children && x.Children.length > 0) {
		// 					let newChild = x.Children.filter((m) => m.Selected);
		// 					// console.log('第三層 newChild:', newChild);
		// 					x.Children = JSON.parse(JSON.stringify(newChild));
		// 				}
		// 			});
		// 		}
		// 		return sort(item);
		// 	})
		// 	.filter((r) => r.Children.length > 0);

		this.navList = buildData(list);
	}

	// - 取得現在路徑
	mainContentPageUrl() {
		const url = this.router.url;
		const cleanUrl = url.split('#')[0];
		return cleanUrl;
	}

	// - focus content and scroll
	focusContent(elementId) {
		const contentElement = document.getElementById(elementId);
		if (contentElement) {
			contentElement.focus();
			contentElement.scrollIntoView({ behavior: 'smooth' });
		}
	}
}
