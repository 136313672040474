import { Injectable } from '@angular/core';
import {
	CanActivate,
	Router,
	ActivatedRouteSnapshot,
	RouterStateSnapshot,
	CanLoad,
	Route,
	CanActivateChild,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs/index';
import { take, tap } from 'rxjs/operators';

import * as fromUser from '@app/core/store/user';
import { MessageService } from '@app/core/services';
import { UserLoginData } from '../models';

@Injectable({
	providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
	loginStatus$: Observable<boolean>;
	currentUser$: Observable<UserLoginData>;
	currentUser: UserLoginData;

	constructor(
		private store: Store<fromUser.UserState>,
		private router: Router,
		private messageService: MessageService
	) {
		this.loginStatus$ = store.select(fromUser.getIsLogin);
		this.currentUser$ = store.select(fromUser.getCurrentUser);
		this.currentUser$.subscribe((v) => {
			this.currentUser = v;
		});
	}

	/** 決定是否可進入目標路由  */
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		let url: string = state.url;
		console.log('canActivate', url);
		return this.checkLogin(url);
	}

	/** 決定是否可進入目標路由之子路由  */
	canActivateChild(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		let url: string = state.url;
		console.log('canActivateChild', url);
		return this.checkUrlPermission(url);
	}

	/** 決定這個路由模組是否可被載入 (延遲或預先載入模組的權限) */
	canLoad(route: Route): Observable<boolean> {
		let url = `/${route.path}`;
		console.log('canLoad:', url);
		return this.checkUrlPermission(url);
	}

	/**
	 * 檢查登入狀態
	 * @param url 當前url
	 * @returns
	 */
	checkLogin(url: string): Observable<boolean> {
		return this.loginStatus$.pipe(
			tap(async (status) => {
				console.log('loginStatus登入狀態:', status);
				// 登入狀態 = false
				if (!status) {
					alert('登入逾時，請重新登入平台。');
					this.router.navigate(['Web/Login']);
				}

				// 登入狀態 = true
				if (status) {
					// 檢查權限
					this.checkUrlPermission(url);
				}
			}),
			take(1)
		);
	}

	/**
	 * 檢查登入狀態
	 * @param url 當前url
	 * @returns
	 */
	checkUrlPermission(url: string): Observable<boolean> {
		if (
			this.currentUser.Permission.filter((permission) => {
				let regexpUrl = new RegExp(permission);
				return regexpUrl.test(url);
			}).length > 0
		) {
			return of(true);
		} else {
			this.messageService.add({
				severity: 'error',
				summary: '無權限訪問該頁面',
				detail: '請聯絡系統管理員',
				life: 3000,
			});
			return of(false);
		}
	}
}
