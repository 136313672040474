import { UserActions, UserActionTypes } from './user.actions';
import { UserLoginData } from '@app/core/models';
import { createReducer, on } from '@ngrx/store';

export interface UserState {
	isLogin: boolean;
	error: any;
	currentUser: UserLoginData | null;
}

export const userInitialState: UserState = {
	isLogin: false,
	error: null,
	currentUser: null,
};

export function userReducer(state: UserState = userInitialState, action: UserActions): UserState {
	switch (action.type) {
		case UserActionTypes.LogoutSuccess:
			return userInitialState;
		case UserActionTypes.LoginFail:
			return {
				...state,
				isLogin: false,
				error: action.payload,
				currentUser: null,
			};
		case UserActionTypes.LoginFailNoPrompt:
			return {
				...state,
				isLogin: false,
				error: action.payload,
				currentUser: null,
			};
		case UserActionTypes.LoginSuccess:
			return {
				...state,
				currentUser: action.payload,
				isLogin: true,
			};
		case UserActionTypes.UpdateUserSuccess:
			return {
				...state,
				currentUser: action.payload,
				isLogin: true,
			};
		case UserActionTypes.SingleSignOnSuccess:
			return {
				...state,
				currentUser: action.payload,
				isLogin: true,
			};
		default:
			return state;
	}
}
