<div class="entrance-footer-ctn">
	<div class="service-remarks-ctn">
		<div><h2>手語視訊轉譯中心</h2></div>

		<div>
			<p-divider
				layout="vertical"
				class="h-100"></p-divider>
			<p-divider layout="horizontal"></p-divider>
		</div>

		<div>
			<p>轉譯服務時間</p>
			<p>週一至週六：09:00 ~ 19:00 (國定假日除外)</p>
		</div>

		<div>
			<p-divider
				layout="vertical"
				class="h-100"></p-divider>
			<p-divider layout="horizontal"></p-divider>
		</div>

		<div>
			<p>客服資訊</p>
			<p>轉譯服務專線：(02)7755-0101</p>
			<p>行政事務專線：(02)7755-0100</p>
			<p>E-mail：vrs.sfaa@gmail.com</p>
		</div>
	</div>

	<div class="site-notes-ctn">
		<div></div>

		<div>
			<p>
				更新時間：
				<span [style.color]="'#FFC453'">{{ environment.lastUpdate }}</span>
			</p>
			<p>COPYRIGHT © 2024 手語視訊轉譯中心</p>
		</div>

		<div>
			<div>
				<a
					#goFooter
					[routerLink]="mainContentPageUrl()"
					fragment="AZ"
					id="AZ"
					title="下方功能區塊"
					accesskey="Z"
					name="Z"
					class="mr-2"
					(click)="focusContent('AZ')"
					(keydown)="$event.key === 'Enter' ? goFooter.click() : null">
					:::
				</a>
			</div>

			<!-- <div>
				<a class="custom-focus" tabindex="0" [routerLink]="'/Guide/Sitemap'" title="前往網站導覽">網站導覽</a>
			</div>

			<div>
				<p-divider layout="vertical"></p-divider>
			</div> -->

			<div>
				<a
					class="custom-focus-heavier"
					routerLink="/Terms/Privacy"
					title="前往隱私權政策">
					隱私權政策
				</a>
			</div>

			<div>
				<p-divider layout="vertical"></p-divider>
			</div>

			<div>
				<a
					class="custom-focus-heavier"
					routerLink="/Terms/InfoSecurity"
					title="前往資訊安全政策">
					資訊安全政策
				</a>
			</div>

			<div>
				<p-divider layout="vertical"></p-divider>
			</div>

			<div>
				<a
					class="custom-focus-heavier"
					routerLink="/Terms/OpenDataStatement"
					title="政府網站資料開放宣告">
					政府網站資料開放宣告
				</a>
			</div>

			<div class="px-2"></div>
			<a
				class="custom-focus-heavier"
				href="https://accessibility.moda.gov.tw/Applications/Detail?category=20231219180312"
				title="無障礙網站">
				<img
					src="../../../../assets/img/web/accessibility-badge-aa.png"
					border="0"
					width="88"
					height="31"
					alt="通過AA無障礙網頁檢測" />
			</a>
		</div>
	</div>
</div>
