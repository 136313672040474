import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of } from 'rxjs';
import { config } from '@app/core/app.config';
import { MessageService } from 'primeng/api';
import { catchError, map } from 'rxjs/operators';
import {
	CreateBillboardData,
	UpdateBulletins,
	BulletinsCondition,
	GetBulletinsCondition,
	DeleteBulletinsAttach,
	ClickBulletinInput,
	DownloadBulletinAttachInput,
	DownloadBulletinAllAttachInput,
} from '@app/core/models';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

@Injectable({
	providedIn: 'root',
})
export class BillboardService {
	constructor(
		private http: HttpClient,
		private httpService: HttpClientService,
		private store: Store,
		private messageService: MessageService // Toast
	) {}

	//** Catch Err function
	isCatchErrCode(x, apiName) {
		// console.log('x:', x);
		if (x.status === 401) {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else {
			// console.log(`%c API ${apiName} Error`, 'color:#24b5aa;', x);
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, status: ${x.status} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
		}
	}

	//** 建立 Error 抓錯
	getError(res, apiName) {
		if (res.code === '400') {
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, ${res.exception} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
			return res;
		} else if (res.code === '401') {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code === '1015') {
			this.messageService.add({
				severity: 'error',
				summary: res.message,
				// detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code !== '000') {
			return res;
		} else {
			return res;
		}
	}

	//** 取得系統公告列表 */
	public getBulletinsList(
		params: {
			UsingPaging: boolean;
			NumberOfPperPage: number;
			PageIndex: number;
		} = {
			UsingPaging: false,
			NumberOfPperPage: 0,
			PageIndex: 0,
		}
	): Observable<any> {
		const apiName = 'GetBulletinsList';
		return this.httpService.httpPost(`${config.BillboardUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 記錄公告已讀 */
	public recordBulletinIsRead(params: { Id: string }): Observable<any> {
		const apiName = 'RecordBulletinIsRead';
		return this.httpService.httpPost(`${config.BillboardUrl}/` + apiName, params).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	// /** 新增系統公告 */
	// public createBillboard(params: CreateBillboardData): Observable<any> {
	// 	const apiName = 'CreateBillboard';
	// 	return this.httpService.httpPost(`${config.BillboardUrl}/` + apiName, params).pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }

	// /** 刪除系統公告 */
	// public deleteBillboard(params: BulletinsCondition): Observable<any> {
	// 	const apiName = 'DeleteBulletins';
	// 	return this.httpService.httpPost(`${config.BillboardUrl}/` + apiName, params).pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }

	// /** 刪除系統公告附檔 */
	// public deleteFile(params: DeleteBulletinsAttach[]): Observable<any> {
	// 	const apiName = 'DeleteFile';
	// 	return this.httpService.httpPost(`${config.BillboardUrl}/` + apiName, params).pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }

	// /** 點擊系統公告 */
	// public clickBulletin(params: ClickBulletinInput): Observable<any> {
	// 	const apiName = 'ClickBulletin';
	// 	return this.httpService.httpPost(`${config.BillboardUrl}/` + apiName, params).pipe(
	// 		map((res) => this.getError(res, apiName)),
	// 		catchError((x) => {
	// 			this.isCatchErrCode(x, apiName);
	// 			return of();
	// 		})
	// 	);
	// }

	// /** 下載系統公告單個附件 */
	// public downloadBulletinAttach(params: DownloadBulletinAttachInput): Observable<any> {
	// 	const apiName = 'DownloadBulletinAttach';
	// 	return this.http
	// 		.post(`${config.BillboardUrl}/` + apiName, params, {
	// 			responseType: 'arraybuffer',
	// 		})
	// 		.pipe(
	// 			map((res) => this.getError(res, apiName)),
	// 			catchError((x) => {
	// 				this.isCatchErrCode(x, apiName);
	// 				return of();
	// 			})
	// 		);
	// }

	// /** 下載系統公告所有附件 */
	// public downloadBulletinAllAttach(params: DownloadBulletinAllAttachInput): Observable<any> {
	// 	const apiName = 'DownloadBulletinAllAttach';
	// 	return this.http
	// 		.post(`${config.BillboardUrl}/` + apiName, params, {
	// 			responseType: 'arraybuffer',
	// 		})
	// 		.pipe(
	// 			map((res) => this.getError(res, apiName)),
	// 			catchError((x) => {
	// 				this.isCatchErrCode(x, apiName);
	// 				return of();
	// 			})
	// 		);
	// }
}
