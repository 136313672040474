import { NgModule } from '@angular/core';

// 一般及第三方匯入
import { GeneralAndThirdPartyModule } from '@app/shared/general-and-third-party.module';

// layout/I18n...
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { UserModule } from '@app/shared/user/user.module';
import { VoiceControlModule } from '@app/shared/voice-control/voice-control.module';
import { UtilsModule } from '@app/shared/utils/utils.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';

import { SmartadminLayoutModule } from '@app/shared/layout';
import { SmartadminWidgetsModule } from '@app/shared/widgets/smartadmin-widgets.module';
import { SmartadminFormsLiteModule } from '@app/shared/forms/smartadmin-forms-lite.module';
import { SmartProgressbarModule } from '@app/shared/ui/smart-progressbar/smart-progressbar.module';

// custom shared Module
import { DatepickerModule } from '@app/shared/datepicker/datepicker.module';
import { PtableModule } from '@app/shared/p-table/ptable.module';
import { AvatarModule } from 'primeng/avatar';

@NgModule({
	imports: [
		// 一般及第三方匯入
		GeneralAndThirdPartyModule,

		// layout/I18n...
		I18nModule,
		UserModule,
		VoiceControlModule,
		UtilsModule,
		PipesModule,

		SmartadminLayoutModule,
		SmartadminWidgetsModule,
		SmartadminFormsLiteModule,
		SmartProgressbarModule,

		// custom shared Module
		DatepickerModule,
		PtableModule,
		AvatarModule,
	],
	exports: [
		// 一般及第三方匯入
		GeneralAndThirdPartyModule,

		// layout/I18n...
		I18nModule,
		UserModule,
		VoiceControlModule,
		UtilsModule,
		PipesModule,

		SmartadminLayoutModule,
		SmartadminWidgetsModule,
		SmartadminFormsLiteModule,
		SmartProgressbarModule,

		// custom shared Module
		DatepickerModule,
		PtableModule,
		AvatarModule,
	],
})
export class SharedModule {}
