import { Injectable, Injector } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpResponse,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
// import { Store } from "@ngrx/store";
// import * as fromUser from '@app/core/store/user';

@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
	constructor(
		private injector: Injector // private store: Store<fromUser.UserState>,
	) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let userInfo: any = localStorage.getItem('user');
		let captchaKey: any = localStorage.getItem('captchaKey');
		let userId;
		let token;

		if (userInfo) {
			userInfo = JSON.parse(userInfo);
			if (userInfo.isLogin) {
				userId = userInfo.currentUser.UserId;
				token = userInfo.currentUser.JWTToken;
			}
		}

		const customRequest = userId
			? // ! 有 userId，代表已經登入，植入這些 headers
			  request.clone({
					headers: request.headers
						.set('ClientId', userId)
						.set('ApiKey', '5a0e837088b649648b14c8396274348c')
						.set('Authorization', `Bearer ${token}`),
			  })
			: captchaKey
			? // ! 沒有 userId 代表還沒登入，而 localStorage captchaKey 已經有資料，代表準備登入，植入這個 header
			  request.clone({ headers: request.headers.set('Captcha-Key', captchaKey) })
			: // ! 沒有 userId 代表還沒登入，且 localStorage captchaKey 尚未有資料，不植入 header
			  request;

		// 檢查 token 是否過期
		if (userInfo && userInfo.currentUser && userInfo.currentUser.JWTTokenExpireTime) {
			const expirationTime = new Date(userInfo.currentUser.JWTTokenExpireTime);
			if (expirationTime < new Date()) {
				console.log('expirationTime:', expirationTime);
				// token 過期，清除登入資訊
				localStorage.removeItem('user');
			}
		}

		return next.handle(customRequest);
		// .do((ev: HttpEvent<any>) => {
		//   if (ev instanceof HttpResponse) {
		//     // console.log('processing response', ev);
		//   }
		// })
		// .catch(response => {
		//   if (response instanceof HttpErrorResponse) {
		//     // console.log('processing http error', response);
		//   }

		// throw已廢棄
		//   return Observable.throw(response);
		// });

		// return this.store.select(fromUser.getCurrentUser).take(1).flatMap((modifiedReq) => {
		//   // console.log(modifiedReq);
		//   const customRequest = modifiedReq ? request.clone({
		//     headers: request.headers.set("ClientId", modifiedReq.UserId).set("ApiKey", "5a0e837088b649648b14c8396274348c")
		//   }) : request;

		//   return next.handle(customRequest)
		//     .do((ev: HttpEvent<any>) => {
		//       if (ev instanceof HttpResponse) {
		//         // console.log('processing response', ev);
		//       }
		//     })
		//     .catch(response => {
		//       if (response instanceof HttpErrorResponse) {
		//         // console.log('processing http error', response);
		//       }

		//       return Observable.throw(response);
		//     });
		// });
	}
}
