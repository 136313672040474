import { Component, AfterViewInit } from '@angular/core';
import { SyscodeService } from '@app/core/services';
import { SelectItem } from 'primeng/api';
import { SysCodeType } from './core/models';
import { PrimeNGConfig } from 'primeng/api';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';

import { environment } from '@env/environment';

@Component({
	selector: 'app-root',
	template: '<router-outlet></router-outlet><p-toast></p-toast>',
})
export class AppComponent {
	title = 'sa';

	constructor(
		public syscodeService: SyscodeService,
		private primengConfig: PrimeNGConfig,
		private router: Router,
		private titleService: Title
	) {
		this.replaceFifthStyleTag();
	}

	ngOnInit() {
		// INFO:初始化 PrimNG
		this.primengConfig.ripple = true;
		$('html, body').animate({ scrollTop: 0 }, 100);
		this.primengConfig.setTranslation({
			accept: '確認',
			reject: '取消',
			dayNames: ['日', '一', '二', '三', '四', '五', '六'],
			dayNamesShort: ['日', '一', '二', '三', '四', '五', '六'],
			dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
			monthNames: [
				'一月',
				'二月',
				'三月',
				'四月',
				'五月',
				'六月',
				'七月',
				'八月',
				'九月',
				'十月',
				'十一月',
				'十二月',
			],
			monthNamesShort: [
				'一月',
				'二月',
				'三月',
				'四月',
				'五月',
				'六月',
				'七月',
				'八月',
				'九月',
				'十月',
				'十一月',
				'十二月',
			],
			today: '今天',
			clear: '清除',
			weekHeader: 'Wk',
			// 參數
			// startsWith: 'Starts with',
			// contains: 'Contains',
			// notContains: 'Not contains',
			// endsWith: 'Ends with',
			// equals: 'Equals',
			// notEquals: 'Not equals',
			// noFilter: 'No Filter',
			// lt: 'Less than',
			// lte: 'Less than or equal to',
			// gt: 'Greater than',
			// gte: 'Greater than or equal to',
			// is: 'Is',
			// isNot: 'Is not',
			// before: 'Before',
			// after: 'After',
			// dateIs: 'Date is',
			// dateIsNot: 'Date is not',
			// dateBefore: 'Date is before',
			// dateAfter: 'Date is after',
			// clear: 'Clear',
			// apply: 'Apply',
			// matchAll: 'Match All',
			// matchAny: 'Match Any',
			// addRule: 'Add Rule',
			// removeRule: 'Remove Rule',
			// accept: 'Yes',
			// reject: 'No',
			// choose: 'Choose',
			// upload: 'Upload',
			// cancel: 'Cancel',
			// dayNames: [
			// 	'Sunday',
			// 	'Monday',
			// 	'Tuesday',
			// 	'Wednesday',
			// 	'Thursday',
			// 	'Friday',
			// 	'Saturday',
			// ],
			// dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
			// dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
			// monthNames: [
			// 	'January',
			// 	'February',
			// 	'March',
			// 	'April',
			// 	'May',
			// 	'June',
			// 	'July',
			// 	'August',
			// 	'September',
			// 	'October',
			// 	'November',
			// 	'December',
			// ],
			// monthNamesShort: [
			// 	'Jan',
			// 	'Feb',
			// 	'Mar',
			// 	'Apr',
			// 	'May',
			// 	'Jun',
			// 	'Jul',
			// 	'Aug',
			// 	'Sep',
			// 	'Oct',
			// 	'Nov',
			// 	'Dec',
			// ],
			// dateFormat: 'mm/dd/yy',
			// firstDayOfWeek: 0,
			// today: 'Today',
			// weekHeader: 'Wk',
			// weak: 'Weak',
			// medium: 'Medium',
			// strong: 'Strong',

			// emptyMessage: 'No results found',
			// emptyFilterMessage: 'No results found',
		});

		//Called after the constructor, initializing input properties, and the first call to ngOnChanges.
		//Add 'implements OnInit' to the class.

		// <link href="css/ext/bootstrap-toggle.min.css" rel="stylesheet" />
		// await this.syscodeService.getSyscodeCodeNodeType({ Type: SysCodeType.NodeType }).subscribe(res => (res));
		this.titleDynamic();
	}

	fixToastAccessibility() {
		const targetNode = document.body;
		const config = { attributes: false, childList: true, subtree: true };
		const callback = function (mutationsList, observer) {
			for (let mutation of mutationsList) {
				if (mutation.type === 'childList') {
					mutation.addedNodes.forEach((node) => {
						if (node.classList && node.classList.contains('p-toast-icon-close')) {
							node.setAttribute('aria-label', '關閉提示彈窗');
						}

						if (
							node.classList &&
							node.classList.contains('p-dialog-header-icon', 'p-dialog-header-close')
						) {
							node.setAttribute('aria-label', '關閉彈出對話窗');
						}
					});
				}
			}
		};
		const observer = new MutationObserver(callback);
		observer.observe(targetNode, config);
	}

	ngAfterViewInit() {
		this.fixToastAccessibility();
	}

	titleDynamic() {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				map(() => {
					let route: ActivatedRoute = this.router.routerState.root;
					let routeTitle = '';
					while (route!.firstChild) {
						route = route.firstChild;
					}
					if (route.snapshot.data['title']) {
						routeTitle = route!.snapshot.data['title'];
					}

					return routeTitle;
				})
			)
			.subscribe((title: string) => {
				if (title) {
					this.titleService.setTitle(` ${environment.projectNameCH} - ${title}`);
				}
			});
	}

	// - 覆蓋 primeng style injection for accessibility
	replaceFifthStyleTag() {
		const targetNode = document.body;
		const config = { attributes: false, childList: true, subtree: true };
		const callback = function (mutationsList, observer) {
			for (let mutation of mutationsList) {
				if (mutation.type === 'childList') {
					const styleTags = document.getElementsByTagName('style');
					for (let i = 0; i < styleTags.length; i++) {
						const styleTag = styleTags[i];
						if (styleTag.innerText.startsWith('.p-dialog-mask')) {
							styleTag.innerText = styleReplacement;
							observer.disconnect();
							return;
						}
					}
				}
			}
		};
		const observer = new MutationObserver(callback);
		observer.observe(targetNode, config);
	}
}

const styleReplacement =
	'.p-dialog-mask{position:fixed;top:0;left:0;width:100%;height:100%;display:flex;justify-content:center;align-items:center;pointer-events:none}.p-dialog-mask.p-component-overlay{pointer-events:auto}.p-dialog{display:flex;flex-direction:column;pointer-events:auto;max-height:90%;transform:scale(1);position:relative}.p-dialog-content{overflow-y:auto;flex-grow:1}.p-dialog-header{display:flex;align-items:center;justify-content:space-between;flex-shrink:0}.p-dialog-draggable .p-dialog-header{cursor:move}.p-dialog-footer{flex-shrink:0}.p-dialog .p-dialog-header-icons{display:flex;align-items:center}.p-dialog .p-dialog-header-icon{display:flex;align-items:center;justify-content:center;overflow:hidden;position:relative}.p-fluid .p-dialog-footer .p-button{width:auto}.p-dialog-top .p-dialog,.p-dialog-bottom .p-dialog,.p-dialog-left .p-dialog,.p-dialog-right .p-dialog,.p-dialog-top-left .p-dialog,.p-dialog-top-right .p-dialog,.p-dialog-bottom-left .p-dialog,.p-dialog-bottom-right .p-dialog{margin:.75rem;transform:translate(0)}.p-dialog-maximized{transition:none;transform:none;width:100vw!important;height:100vh!important;top:0px!important;left:0px!important;max-height:100%;height:100%}.p-dialog-maximized .p-dialog-content{flex-grow:1}.p-dialog-left{justify-content:flex-start}.p-dialog-right{justify-content:flex-end}.p-dialog-top{align-items:flex-start}.p-dialog-top-left{justify-content:flex-start;align-items:flex-start}.p-dialog-top-right{justify-content:flex-end;align-items:flex-start}.p-dialog-bottom{align-items:flex-end}.p-dialog-bottom-left{justify-content:flex-start;align-items:flex-end}.p-dialog-bottom-right{justify-content:flex-end;align-items:flex-end}.p-dialog .p-resizable-handle{position:absolute;font-size:0.006rem;display:block;cursor:se-resize;width:12px;height:12px;right:1px;bottom:1px}.p-confirm-dialog .p-dialog-content{display:flex;align-items:center}';
