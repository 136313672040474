import { StorageService } from './storage.service';
import { AuthTokenService } from './auth-token.service';

import { TokenInterceptor } from './token.interceptor';
import { MyHttpInterceptor } from './http.interceptor';
import { JsonApiService } from './json-api.service';
import { UserService } from './user.service';
import { BodyService } from './body.service';
import { LayoutService } from './layout.service';
import { SoundService } from './sound.service';
import { SyscodeService } from './syscode.service';
import { ExcelService } from './excel.service';
import { BillboardService } from './billboard.service';
import { RoleGroupService } from './rolegroup.service';

import * as fromVoice from './voice';
import { LogAuditService } from './log-audit.service';
import { NetworkService } from './network.service';
import { ProblemReportService } from './problem-report.service';
import { DeskService } from './desk.service';
import { NotificationService } from './notification.service';

// primeNg
import { MessageService, ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';

export const services = [
	// ! 以下註解，避免跑 UT 跑到
	// AuthService,
	// TokenInterceptor,
	// BodyService,
	// SoundService,
	// fromVoice.VoiceControlService,
	// fromVoice.VoiceRecognitionService,
	// RoleGroupService,
	// LogAuditService,
	// NetworkService,
	// ExcelService,
	// RoleGroupService,
	// LogAuditService,
	// NetworkService,

	ProblemReportService,
	SyscodeService,
	StorageService,
	AuthTokenService,
	MyHttpInterceptor,
	JsonApiService,
	UserService,
	LayoutService,
	BillboardService,
	MessageService,
	ConfirmationService,
	DialogService,

	// ! VRS Start
	DeskService,
	NotificationService,
];

// ! 內容有下註解，避免跑 UT 跑到
// export * from './excel.service';
// export * from './voice';
// export * from './log-audit.service';
// export * from './network.service';
// export * from './rolegroup.service';
// export * from './sound.service';
// export * from './body.service';
// export * from './auth-token.service';
// export * from './token.interceptor';

export * from './storage.service';
export * from './problem-report.service';
export * from './syscode.service';
// ! ========================

export * from './json-api.service';
export * from './billboard.service';

export * from './layout.service';
export * from './user.service';
export * from './desk.service';
export * from './notification.service';

export * from 'primeng/api';
