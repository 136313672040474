import { Component } from '@angular/core';
import { LayoutService } from '@app/core/services';
import * as fromLayout from '@app/core/store/layout';
import { Store } from '@ngrx/store';

@Component({
	selector: 'sa-minify-menu',
	template: `
		<span
			class="minifyme custom-focus"
			(click)="toggle()"
			title="選單展開及縮小按鈕"
			tabindex="0"
			(keydown)="$event.key === 'Enter' ? toggle() : null"
		>
			<em class="fa fa-arrow-circle-left hit"></em>
		</span>
	`,
})
export class MinifyMenuComponent {
	constructor(private store: Store<any>, private layoutService: LayoutService) {}

	toggle() {
		this.layoutService.onMinifyMenu();
		this.layoutService.onCollapseMenu(false);
	}
}
