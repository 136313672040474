<div class="main__layout__entrance">
	<sa-header></sa-header>

	<aside id="left-panel">
		<div class="d-flex justify-content-center">
			<a
				#goSide
				[routerLink]="mainContentPageUrl()"
				fragment="AL"
				title="左方相關連結區"
				id="AL"
				accesskey="L"
				name="L"
				tabindex="0"
				(click)="focusContent('AL')"
				(keydown)="$event.key === 'Enter' ? goSide.click() : null">
				:::
			</a>
		</div>
		<sa-login-info></sa-login-info>
		<nav>
			<ul saSmartMenu>
				<li
					*ngIf="currentUser"
					tabindex="0">
					<a
						[routerLink]="'Desk'"
						style="display: flex; align-items: baseline">
						<em
							class="fa-solid fa-headphones fa-lg ml-1 mr-3"
							aria-hidden="true"></em>
						<span class="menu-item-parent">進入服務台</span>
					</a>
				</li>
				<li
					(click)="navigateToPageWithAnchor('/Home/Entrance', 'about')"
					tabindex="0">
					<a
						style="display: flex; align-items: baseline"
						title="移至關於我們">
						<em
							class="fa-solid fa-address-card fa-lg ml-1 mr-3"
							aria-hidden="true"></em>
						<span class="menu-item-parent">關於我們</span>
					</a>
				</li>
				<li
					(click)="navigateToPageWithAnchor('/Home/Entrance', 'service')"
					tabindex="0">
					<a
						style="display: flex; align-items: baseline"
						title="移至服務說明">
						<em
							class="fa-solid fa-handshake fa-lg mr-3"
							aria-hidden="true"></em>
						<span class="menu-item-parent">服務說明</span>
					</a>
				</li>
				<li
					tabindex="0"
					[routerLink]="'/Guide/Sitemap'">
					<a
						style="display: flex; align-items: baseline"
						title="移至網站導覽">
						<em
							class="fa-solid fa-map fa-lg ml-1 mr-3"
							aria-hidden="true"></em>
						<span class="menu-item-parent">網站導覽</span>
					</a>
				</li>
				<li
					tabindex="0"
					[routerLink]="'/Guide/Faq'">
					<a
						style="display: flex; align-items: baseline"
						title="移至常見問題">
						<em
							class="fa-solid fa-question fa-lg ml-1 mr-4"
							aria-hidden="true"></em>
						<span class="menu-item-parent">&nbsp;常見問題</span>
					</a>
				</li>
			</ul>
		</nav>
	</aside>

	<div
		id="main"
		class=""
		role="main"
		[@routerTransition]="getState(o?._activatedRoute ? o : '')"
		style="margin-left: 0">
		<router-outlet #o="outlet"></router-outlet>
		<p-toast></p-toast>
	</div>

	<sa-entrance-footer *ngIf="!router.url.includes('Login')"></sa-entrance-footer>
	<!-- <sa-layout-switcher class="d-none demo_custom"></sa-layout-switcher> -->

	<p-blockUI
		[baseZIndex]="999999"
		[blocked]="this.layoutService.pageLoading$ | async">
		<p-progressSpinner></p-progressSpinner>
	</p-blockUI>
</div>
