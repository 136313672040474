import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainLayoutComponent } from './shared/layout/app-layouts/main-layout.component';
import { EntranceLayoutComponent } from './shared/layout/app-layouts/entrance-layout.component';
import { NIALayoutComponent } from '@app/shared/layout/app-layouts/nia-layout.component';
import { AuthGuard } from '@app/core/guards/auth.guard';
import { environment } from '../environments/environment';

// 環境變數
let _environment = environment;

const routes: Routes = [
	{
		path: '',
		redirectTo: _environment.singleSignIn ? 'error/500' : 'Home/Entrance',
		pathMatch: 'full',
	},
	{
		path: '',
		component: EntranceLayoutComponent,
		data: { pageTitle: 'Index' },
		children: [
			{
				path: 'Home',
				loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
				data: {
					pageTitle: 'Home',
				},
			},
			{
				path: 'Web', // login 登入組件
				component: NIALayoutComponent,
				loadChildren: () => import('./features/web/web.module').then(m => m.WebModule),
			},
			{
				path: 'Terms', // 政策
				component: NIALayoutComponent,
				loadChildren: () => import('./features/terms/terms.module').then(m => m.TermsModule),
			},
			{
				path: 'Guide', // 引導 for accessibility
				component: NIALayoutComponent,
				loadChildren: () => import('./features/guide/guide.module').then(m => m.GuideModule),
			},
			{
				path: 'Store', // app store 導頁
				component: NIALayoutComponent,
				loadChildren: () => import('./features/store/store.module').then(m => m.StoreModule),
			},
		],
	},

	{
		path: 'AppView',
		component: NIALayoutComponent,
		data: { pageTitle: 'AppView' },
		children: [
			{
				path: 'InfoSecurity',
				loadChildren: () =>
					import('./features/terms/info-security/info-security.module').then(m => m.InfoSecurityModule),
				data: {
					pageTitle: 'InfoSecurity',
				},
			},
			{
				path: 'OpenDataStatement',
				loadChildren: () =>
					import('./features/terms/open-data-statement/open-data-statement.module').then(
						m => m.OpenDataStatementModule,
					),
				data: {
					pageTitle: 'OpenDataStatement',
				},
			},
			{
				path: 'Privacy',
				loadChildren: () => import('./features/terms/privacy/privacy.module').then(m => m.PrivacyModule),
				data: {
					pageTitle: 'Privacy',
				},
			},
		],
	},

	{
		path: '',
		canActivate: [AuthGuard],
		canLoad: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: MainLayoutComponent,
		data: { pageTitle: 'Home' },
		children: [
			// 新router -------------------------------
			{
				// 服務台一覽
				path: 'Desk',
				loadChildren: () => import('./features/desk/desk.module').then(m => m.DeskModule),
				data: {
					pageTitle: 'Desk',
				},
			},
			{
				// 服務紀錄
				path: 'ServiceLog',
				loadChildren: () =>
					import('./features/service-log/service-log.module').then(
						m => m.ServiceLogModule,
					),
				data: {
					pageTitle: 'Service Log',
				},
			},
			{
				// 通知中心
				path: 'NotificationCenter',
				loadChildren: () =>
					import('./features/notification-center/notification-center.module').then(
						m => m.NotificationCenterModule,
					),
				data: {
					pageTitle: 'Notification Center',
				},
			},
			{
				// 會員資料
				path: 'MemberInfo',
				loadChildren: () => import('./features/member-info/member-info.module').then(m => m.MemberInfoModule),
				data: {
					pageTitle: 'Member Info',
				},
			},
			{
				// 問題回報
				path: 'ReportManagement',
				loadChildren: () => import('./features/report-management/report-management.module').then(m => m.ReportManagementModule),
				data: {
					pageTitle: 'Report Management',
				},
			},
			{
				// 視訊裝置設定
				path: 'VideoConfig',
				loadChildren: () => import('./features/video-config/video-config.module').then(m => m.VideoConfigModule),
				data: {
					pageTitle: 'Video Config',
				},
			},
			{
				// 回首頁
				path: 'Home',
				loadChildren: () => import('./features/home/home.module').then(m => m.HomeModule),
				data: {
					pageTitle: 'Home',
				},
			},
		],
	},

	{
		path: 'FlowGuide',
		canActivate: [AuthGuard],
		canLoad: [AuthGuard],
		canActivateChild: [AuthGuard],
		component: NIALayoutComponent,
		data: { pageTitle: 'FlowGuide' },
		loadChildren: () => import('./features/flow-guide/flow-guide.module').then(m => m.FlowGuideModule),
	},
	{
		path: 'error',
		loadChildren: () => import('./features/errors/errors.module').then(m => m.ErrorsModule),
	},
	{
		path: 'Blank',
		loadChildren: () => import('./features/blank-page/blank-page.module').then(m => m.BlankPageModule),
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes, _environment.urlUseHash)],
	exports: [RouterModule],
})
export class AppRoutingModule {}
