import { ActivatedRoute } from '@angular/router';
import { FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable } from 'rxjs/Rx';
import * as moment from 'moment';

export class sharedUtils {
	// ! 表單驗證
	/**
	 * 表單驗證：至少填一個
	 * @param validator
	 * @param ignoreField 忽略的欄位
	 */
	public static atLeastOne =
		(validator: ValidatorFn, ignoreField: Array<string> = []) =>
		(group: FormGroup): ValidationErrors | null => {
			const hasAtLeastOne =
				group &&
				group.controls &&
				Object.keys(group.controls).some((k) =>
					ignoreField.indexOf(k) === -1 ? !validator(group.controls[k]) : false
				);

			return hasAtLeastOne
				? null
				: {
						atLeastOne: true,
				  };
		};

	/**
	 * 表單驗證：任n個當中，至少填一個
	 * @param validator
	 * @param controls n個篩選的欄位
	 */
	public static atLeastOneBtw =
		(validator: ValidatorFn, controls: Array<string> = []) =>
		(group: FormGroup): ValidationErrors | null => {
			if (!controls) {
				controls = Object.keys(group.controls);
			}
			const hasAtLeastOne = group && group.controls && controls.some((k) => !validator(group.controls[k]));
			return hasAtLeastOne
				? null
				: {
						atLeastOne: true,
				  };
		};

	/**
	 * 使form裡所有的field都設定為dirty狀態
	 * @param form
	 */
	public static allFieldMarkAsDirty(form: FormGroup) {
		Object.keys(form.controls).forEach((key) => {
			form.get(key).markAsDirty();
		});
	}

	/**
	 * 連動必填欄位
	 * @param form
	 * @param whichFieldChange 哪個欄位改變
	 * @param whichFieldRequired 哪個欄位變必填
	 */
	// public static valueChangesToRequired(form: FormGroup, whichFieldChange: string, whichFieldRequired: string) {
	// 	form.get(whichFieldChange).valueChanges.subscribe((value) => {
	// 		if (value) {
	// 			form.get(whichFieldRequired).setValidators(Validators.required);
	// 		} else {
	// 			form.get(whichFieldRequired).clearValidators();
	// 		}
	// 		form.get(whichFieldRequired).updateValueAndValidity();
	// 	});
	// }

	/**
	 * Blob轉Base64
	 * @param blob
	 * @returns {any}
	 */
	// public static convertBlobToBase64(blob: Blob) {
	// 	return Observable.create((observer) => {
	// 		const reader = new FileReader();

	// 		reader.readAsDataURL(blob);

	// 		reader.onload = (event: any) => {
	// 			observer.next(event.target.result);
	// 			observer.complete();
	// 		};

	// 		reader.onerror = (event: any) => {
	// 			observer.next(event.target.error.code);
	// 			observer.complete();
	// 		};
	// 	});
	// }

	/**
	 * 身分證字號驗證
	 * @param ctrl
	 * @returns
	 */
	public static idValidator(ctrl): any {
		const id = ctrl.value;
		const tab = 'ABCDEFGHJKLMNPQRSTUVXYWZIO';
		const Ary1 = new Array(1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3);
		const Ary2 = new Array(0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5);
		const Mix = new Array(9, 8, 7, 6, 5, 4, 3, 2, 1, 1);

		if (!id) return { invalidId: true };

		if (id.length != 10) return { invalidId: true };

		if (id.charAt(1) != '1' && id.charAt(1) != '2') return { invalidId: true };

		let i = tab.indexOf(id.charAt(0));
		if (i == -1) return { invalidId: true };

		let sum = Ary1[i] + Ary2[i] * 9;
		for (i = 1; i < 10; i++) {
			let v = parseInt(id.charAt(i));
			if (isNaN(v)) return { invalidId: true };
			sum = sum + v * Mix[i];
		}

		if (sum % 10 != 0) return { invalidId: true };
	}

	/**
	 * 姓名驗證(含中、英文)
	 * @param ctrl
	 * @returns
	 */
	public static nameValidator(ctrl): any {
		const { value, pristine, isTouched } = ctrl;
		const NAME_REGEXP = /^[\u4e00-\u9fa5_a-zA-Z_ ]+$/;
		// if (pristine) return null;
		// if (isTouched || value == '') return ctrl.markAsUntouched();
		// ctrl.markAsTouched();
		if (NAME_REGEXP.test(value)) return null;
		return { invalidName: true };
	}

	/**
	 * 多個姓名驗證(含中、英文)
	 * @param ctrl
	 * @returns
	 */
	// public static multipleNameValidator(ctrl): any {
	// 	const { value, pristine, isTouched } = ctrl;
	// 	const NAME_REGEXP = /^[\u4e00-\u9fa5_a-zA-Z_,_ ]+$/;
	// 	if (pristine) return null;
	// 	if (isTouched || value == '') return ctrl.markAsUntouched();
	// 	ctrl.markAsTouched();
	// 	if (NAME_REGEXP.test(value)) return null;
	// 	return { invalidName: true };
	// }

	/**
	 * 市話驗證
	 * @param ctrl
	 * @returns
	 */
	// public static telValidator(ctrl): any {
	// 	const { value, pristine, isTouched } = ctrl;
	// 	const NAME_REGEXP = /(\d{2,3}-?|\(\d{2,3}\))\d{3,4}-?\d{4}/;
	// 	if (pristine) return null;
	// 	if (isTouched || value == '') return ctrl.markAsUntouched();
	// 	ctrl.markAsTouched();
	// 	if (NAME_REGEXP.test(value)) return null;
	// 	return { invalidName: true };
	// }

	/**
	 * 行動電話驗證
	 * @param ctrl
	 * @returns
	 */
	public static mobileValidator(ctrl): any {
		const { value, pristine, isTouched } = ctrl;
		const NAME_REGEXP = /09\d{2}(\d{6}|-\d{3}-\d{3})/;
		if (pristine) return null;
		if (isTouched || value == '') return ctrl.markAsUntouched();
		ctrl.markAsTouched();
		if (NAME_REGEXP.test(value)) return null;
		return { invalidName: true };
	}

	/**
	 * 密碼驗證
	 * @param ctrl
	 * @returns
	 */
	public static pwValidator(ctrl): any {
		//最少八碼,須符合至少三項,大寫, 小寫, 數字, 特殊符號(!#$%&)
		const { value, pristine, isTouched } = ctrl;
		const NAME_REGEXP =
			/^(?:(?=.*[a-z])(?=.*[A-Z])(?=.*\d)|(?=.*[a-z])(?=.*[A-Z])(?=.*[!#$%&])|(?=.*[a-z])(?=.*\d)(?=.*[!#$%&])|(?=.*[A-Z])(?=.*\d)(?=.*[!#$%&]))[A-Za-z\d!#$%&]{8,}$/;
		if (pristine) return null;
		if (isTouched || value === '') return ctrl.markAsUntouched();
		ctrl.markAsTouched();
		if (NAME_REGEXP.test(value)) return null;
		return { invalidName: true };
	}

	/**
	 * 密碼驗證：回傳個別錯誤訊息：最少12碼｜大寫小寫｜數字｜特殊符號
	 * @param ctrl
	 * @returns
	 */
	public static pwValidatorWithDetailError(ctrl): any {
		const { value } = ctrl;
		const MIN_LENGTH = 12;
		const LOWERCASE_REGEXP = /(?=.*[a-z])/;
		const UPPERCASE_REGEXP = /(?=.*[A-Z])/;
		const NUMBER_REGEXP = /(?=.*\d)/;
		const SYMBOL_REGEXP = /(?=.*[!@#$%^&*()\-_=+[{\]}\\|;:'",<.>/?])/;

		let errors = {};

		if (!value) {
			errors['required'] = true;
		} else {
			if (value.length < MIN_LENGTH) {
				errors['length'] = true;
			}
			if (!LOWERCASE_REGEXP.test(value) || !UPPERCASE_REGEXP.test(value)) {
				errors['upperCaseLowerCase'] = true;
			}
			if (!NUMBER_REGEXP.test(value)) {
				errors['number'] = true;
			}
			if (!SYMBOL_REGEXP.test(value)) {
				errors['symbol'] = true;
			}
		}

		return Object.keys(errors).length ? errors : null;
	}

	/**
	 * 生日驗證：input format 1131212，不得輸入無效日期，或大於今天
	 * @param ctrl
	 * @returns
	 */
	public static birthValidatorWithDetailError(ctrl): any {
		const { value } = ctrl;

		let inputStringArr = [...value];
		let firstThreeChars = inputStringArr.splice(0, 3).join('');
		let result = (parseInt(firstThreeChars) + 1911).toString() + inputStringArr.join('');

		const parsedDate = moment(result, 'YYYYMMDD');

		if (parsedDate.isValid()) {
			const today = moment();
			const noLater = parsedDate.isSameOrBefore(today, 'day');
			if (noLater) return null;
			if (!noLater) return { isValid: false, error: 'Date is later than today' };
		}

		if (!parsedDate.isValid()) return { isValid: false, error: 'Invalid date' };
	}

	/**
	 * 密碼驗證：確定新密碼與舊密碼一樣，否則回傳錯誤
	 * @param ctrl
	 * @returns
	 */
	public static pwConfirmation(ctrl1, ctrl2): any {
		return (formGroup: FormGroup) => {
			const control1 = formGroup.controls[ctrl1];
			const control2 = formGroup.controls[ctrl2];

			if (control1.value !== control2.value) {
				control2.setErrors({ confirmPW: true });
			} else {
				if (control2.hasError('confirmPW')) {
					control2.setErrors(null);
				}
			}
		};
	}

	/**
	 * 西元年驗證
	 * @param ctrl
	 * @returns
	 */
	// public static yearValidator(ctrl): any {
	// 	const { value, pristine, isTouched } = ctrl;
	// 	const currentYear = new Date().getFullYear();
	// 	if (pristine) return null;
	// 	if (isTouched || value == '') return ctrl.markAsUntouched();
	// 	ctrl.markAsTouched();
	// 	if (value <= currentYear) return null;
	// 	return { invalidName: true };
	// }

	/**
	 * 民國年驗證
	 * @param ctrl
	 * @returns
	 */
	// public static twYearValidator(ctrl): any {
	// 	const { value, pristine, isTouched } = ctrl;
	// 	const currentYear = new Date().getFullYear() - 1911;
	// 	if (pristine) return null;
	// 	if (isTouched || value == '') return ctrl.markAsUntouched();
	// 	ctrl.markAsTouched();
	// 	if (value <= currentYear) return null;
	// 	return { invalidName: true };
	// }

	/**
	 * 中文字驗證
	 * @param ctrl
	 * @returns
	 */
	// public static chineseValidator(ctrl): any {
	// 	const { value, pristine, isTouched } = ctrl;
	// 	const NAME_REGEXP = /^[\u4e00-\u9fa5]+$/;
	// 	if (pristine) return null;
	// 	if (isTouched || value == '') return ctrl.markAsUntouched();
	// 	ctrl.markAsTouched();
	// 	if (NAME_REGEXP.test(value)) return null;
	// 	return { invalidName: true };
	// }

	/**
	 * e-mail驗證
	 * @param ctrl
	 * @returns
	 */
	public static mailValidator(ctrl): any {
		const { value, pristine, isTouched } = ctrl;
		const NAME_REGEXP = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z]+$/;
		if (pristine) return null;
		if (isTouched || value == '') return ctrl.markAsUntouched();
		ctrl.markAsTouched();
		if (NAME_REGEXP.test(value)) return null;
		return { invalidName: true };
	}

	// ! 其他

	// - 確認 localStroage 有無 deviceId，有就 return 舊的；沒有則建立一個新的儲存在 localStorage
	public static getDeviceId() {
		const deviceId = localStorage.getItem('deviceId');
		if (deviceId) return deviceId;
		else {
			let uuid = this.uuidv4();
			localStorage.setItem('deviceId', uuid);
			return uuid;
		}
	}

	public static uuidv4(): string {
		return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c) => {
			const randomValue = crypto.getRandomValues(new Uint8Array(1))[0];
			return ((c as any) ^ ((randomValue & 15) >> ((c as any) / 4))).toString(16);
		});
	}
}
