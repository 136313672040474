import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { LayoutService } from '@app/core/services';

import { ConfirmationService } from '@app/core/services';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
	selector: 'sa-reset-pw-dialog',
	templateUrl: './reset-pw-dialog.component.html',
	styleUrls: ['./reset-pw-dialog.component.scss'],
})
export class ResetPwDialogComponent implements OnInit {
	constructor(
		public store: Store<fromUser.UserState>,
		public route: ActivatedRoute,
		public router: Router,
		public layoutService: LayoutService,
		public confirmationService: ConfirmationService,
		public ref: DynamicDialogRef,
		public dialogConfig: DynamicDialogConfig
	) {}

	ngOnInit(): void {}

	confirmPwReset() {
		this.router.navigateByUrl('Web/Forget/Outdated');
		this.ref.close();
	}

	closeDialog() {
		this.ref.close();
	}
}
