import { Action } from '@ngrx/store';
import { UserLoginData } from '@app/core/models';

// define action types
export enum UserActionTypes {
	Login = '[user] Login',
	LoginSuccess = '[user] Login Success',
	LoginFail = '[user] Login Fail',
	LoginFailNoPrompt = '[user] Login Fail No Prompt',
	Logout = '[user] Logout',
	LogoutSuccess = '[user] Logout Success',
	LogoutFail = '[user] Logout Fail',
	UpdateUser = '[user] Update User',
	UpdateUserSuccess = '[user] Update User Success',
	UpdateUserFail = '[user] Update User Fail',
	SingleSignOn = '[user] Single SignOn',
	SingleSignOnSuccess = '[user] Single SignOn Success',
	SingleSignOnFail = '[user] Single SignOn Fail',
	KickOffToken = '[user] KickOffToken',
	CreateMemberSuccess = '[user] Create Member Success',
	ReturnNull = '[user] Return Null',
}

// define Actions class
export class LoginAction implements Action {
	readonly type = UserActionTypes.Login;
	constructor(public payload: UserLoginData) {}
}
export class LoginSuccessAction implements Action {
	readonly type = UserActionTypes.LoginSuccess;
	constructor(public payload: UserLoginData) {}
}
export class LoginFailAction implements Action {
	readonly type = UserActionTypes.LoginFail;
	constructor(public payload: any) {}
}
export class LogoutAction implements Action {
	readonly type = UserActionTypes.Logout;
	constructor(public payload: UserLoginData) {}
}
export class LogoutSuccessAction implements Action {
	readonly type = UserActionTypes.LogoutSuccess;
}
export class LogoutFailAction implements Action {
	readonly type = UserActionTypes.LogoutFail;
	constructor(public payload: UserLoginData) {}
}

export class LoginFailNoPromptAction implements Action {
	readonly type = UserActionTypes.LoginFailNoPrompt;
	constructor(public payload: UserLoginData) {}
}

export class UpdateUserAction implements Action {
	readonly type = UserActionTypes.UpdateUser;
	constructor(public payload: UserLoginData) {}
}
export class UpdateUserSuccessAction implements Action {
	readonly type = UserActionTypes.UpdateUserSuccess;
	constructor(public payload: UserLoginData) {}
}
export class UpdateUserFailAction implements Action {
	readonly type = UserActionTypes.UpdateUserFail;
}

export class SingleSignOnAction implements Action {
	readonly type = UserActionTypes.SingleSignOn;
	constructor(public payload: unknown) {}
}
export class SingleSignOnSuccessAction implements Action {
	readonly type = UserActionTypes.SingleSignOnSuccess;
	constructor(public payload: UserLoginData) {}
}
export class SingleSignOnFailAction implements Action {
	readonly type = UserActionTypes.SingleSignOnFail;
	constructor(public payload: any) {}
}
export class KickOffTokenAction implements Action {
	readonly type = UserActionTypes.KickOffToken;
	constructor(public payload?: any) {}
}
export class CreateMemberSuccessAction implements Action {
	readonly type = UserActionTypes.CreateMemberSuccess;
	constructor(public payload?: any) {}
}

export class ReturnNullAction implements Action {
	readonly type = UserActionTypes.ReturnNull;
	constructor(public payload?: any) {} // Optionally, you can define a payload for the action.
}

export type UserActions =
	| LoginAction
	| LoginSuccessAction
	| LoginFailAction
	| LoginFailNoPromptAction
	| LogoutAction
	| LogoutSuccessAction
	| LogoutFailAction
	| UpdateUserAction
	| UpdateUserSuccessAction
	| UpdateUserFailAction
	| SingleSignOnAction
	| SingleSignOnSuccessAction
	| SingleSignOnFailAction
	| KickOffTokenAction
	| CreateMemberSuccessAction
	| ReturnNullAction;
