import { Injectable } from '@angular/core';
import { HttpClientService } from '@app/core/services/HttpClientService';
import { Observable, of } from 'rxjs';
import { config } from '@app/core/app.config';
import { HttpClient } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromUser from '@app/core/store/user';

@Injectable({
	providedIn: 'root',
})
export class NotificationService {
	constructor(
		private http: HttpClient,
		private httpService: HttpClientService,
		private messageService: MessageService, // Toast
		private router: Router,
		private store: Store
	) {}

	//** Catch Err function
	isCatchErrCode(x, apiName) {
		// console.log('x:', x);
		if (x.status === 401) {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else {
			// console.log(`%c API ${apiName} Error`, 'color:#24b5aa;', x);
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, status: ${x.status} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
		}
	}

	//** 建立 Error 抓錯
	getError(res, apiName) {
		if (res.code === '400') {
			this.messageService.add({
				severity: 'error',
				summary: `API ${apiName} Error, ${res.exception} `,
				detail: '請聯繫管理員',
				icon: 'bounce animated',
				life: 5000,
			});
			return res;
		} else if (res.code === '401') {
			this.messageService.add({
				severity: 'error',
				summary: `登入已過期`,
				detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code === '1015') {
			this.messageService.add({
				severity: 'error',
				summary: res.message,
				// detail: '請重新登入',
				icon: 'bounce animated',
				life: 5000,
			});
			this.store.dispatch(new fromUser.LogoutAction({}));
		} else if (res.code !== '000') {
			return res;
		} else {
			return res;
		}
	}

	//** 發送驗證碼 for 手機  */
	public sendSMS(param: { Phone: string }): Observable<any> {
		const apiName = 'SendSMS';
		return this.httpService.httpPost(config.NotificationUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 驗證驗證碼 for 手機 */
	public checkVerificationCode(param: { Phone: string; Code: string; SMSType: 0 }): Observable<any> {
		const apiName = 'CheckVerificationCode';
		return this.httpService.httpPost(config.NotificationUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 發送驗證碼 for 信箱  */
	public sendEmail(param: { Mail: string; MailType: number }): Observable<any> {
		const apiName = 'SendEmail';
		return this.httpService.httpPost(config.NotificationUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 驗證驗證碼 for 信箱 */
	public checkMailVerificationCode(param: { Code: string; Mail: string }): Observable<any> {
		const apiName = 'CheckMailVerificationCode';
		return this.httpService.httpPost(config.NotificationUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 忘記密碼：發送驗證碼 By 身分證 (電話 or 電子郵件) */
	public sendVerificationCodeById(param: { Id: string; CheckVerificationCodeType: number }): Observable<any> {
		const apiName = 'SendVerificationCodeById';
		return this.httpService.httpPost(config.NotificationUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}

	//** 忘記密碼：驗證驗證碼 By 身分證 (電話 or 電子郵件) */
	public checkVerificationCodeById(param: {
		Id: string;
		Code: string;
		CheckVerificationCodeType: number;
	}): Observable<any> {
		const apiName = 'CheckVerificationCodeById';
		return this.httpService.httpPost(config.NotificationUrl + '/' + apiName, param).pipe(
			map((res) => this.getError(res, apiName)),
			catchError((x) => {
				this.isCatchErrCode(x, apiName);
				return of();
			})
		);
	}
}
